import { call, fork, takeLatest } from 'redux-saga/effects';
import { catchError } from '@utils/sentry';
import postData from '@utils/postData';
import { interviewAccept } from './slice';
import { ACCEPT_INTERVIEW } from './queries';
export function* interviewAcceptWorker({ payload }) {
    try {
        const response = (yield call(postData, {
            queryString: ACCEPT_INTERVIEW,
            payload: payload?.data,
        }));
        const confirmInterviewResponse = response?.data?.sch_rsvp_action?.success_count;
        if (payload.callback?.onSuccess && confirmInterviewResponse) {
            yield call(payload.callback.onSuccess, response);
        }
        else {
            throw new Error('An error occurred while confirming the interview');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Confirm Interview', error: error, skipToast: true });
        if (payload.callback?.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* interviewAcceptWatcher() {
    yield takeLatest(interviewAccept.type, interviewAcceptWorker);
}
export function* interviewAcceptSaga() {
    yield fork(interviewAcceptWatcher);
}
