import { gql } from '@apollo/client';
export const GET_FORM_INSTANCE_DATA = gql `
  query getFormInstanceData($id: Int!) {
    frm_form_instance(where: { id: { _eq: $id } }) {
      id
      submitted_by
      form_version {
        form_id
        status
        form {
          name
          id
        }
        id
      }
    }
  }
`;
export const GET_FORM_VERSION_DATA = gql `
  query getFormVersionData($form_id: Int!) {
    frm_form_version(where: { form_id: { _eq: $form_id } }, order_by: { id: desc }, limit: 1) {
      form_id
      status
      id
    }
  }
`;
