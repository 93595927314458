import { createSlice } from '@reduxjs/toolkit';
export const initialState = {};
export const InterviewAcceptSlice = createSlice({
    name: 'interview-decline-scheduling',
    initialState,
    reducers: {
        interviewAccept(_state, _action) { },
    },
});
export const { interviewAccept } = InterviewAcceptSlice.actions;
export default InterviewAcceptSlice.reducer;
