import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import AuthContext from '@contexts/AuthContext';
import { candidateLoginOtpFilledLoginSuccess } from '@utils/mixpanel/mixpanelActions';
import * as Sentry from '@sentry/react';
export const Redirect = () => {
    const { queryParams, tokens } = useContext(AuthContext);
    useEffect(() => {
        candidateLoginOtpFilledLoginSuccess({});
        if (queryParams?.from || queryParams?.redirect_url) {
            let path = `${queryParams?.redirect_url || queryParams?.from}`;
            if (typeof tokens?.accessToken === 'string') {
                const search = new URLSearchParams();
                search.set('accessToken', tokens?.accessToken);
                path = `${queryParams?.redirect_url}?${search.toString()}`;
            }
            window.location.href = path;
        }
        else {
            Sentry.captureMessage(`Redirect page:  ${JSON.stringify(queryParams)}`);
        }
    }, [queryParams?.from]);
    console.log('Redirecting page content: ', queryParams);
    const redirectingText = 'Redirecting...';
    return _jsx("div", { children: redirectingText });
};
