import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useMemo, useRef, useState } from 'react';
import intl, { DEFAULT_LOCALE, changeLanguage } from '@utils/intl';
import { RawIntlProvider } from 'react-intl';
export const LanguageContext = createContext({
    locale: DEFAULT_LOCALE,
    intl,
});
export const LanguageProvider = (props) => {
    const [selectedLanguage, setSelectedLanguage] = useState(DEFAULT_LOCALE);
    const intlInstance = useRef(intl);
    const handleChangeLanguage = useCallback((locale) => {
        intlInstance.current = changeLanguage(locale);
        setSelectedLanguage(locale);
    }, [setSelectedLanguage]);
    const value = useMemo(() => ({
        locale: selectedLanguage,
        intl: intlInstance.current,
        handleChangeLanguage,
    }), [selectedLanguage, intlInstance.current, handleChangeLanguage]);
    return (_jsx(LanguageContext.Provider, { value: value, children: _jsx(RawIntlProvider, { value: intlInstance.current, children: props.children }) }));
};
