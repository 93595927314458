import { call, debounce, fork, put, select, takeLatest } from 'redux-saga/effects';
import { first } from 'lodash';
import { extractPaymentDetailsData, formatCouponData } from '@containers/Payment/payment.helper';
import { selectCouponDetails } from '@containers/Payment/selectors';
import { selectQueryParams } from '@containers/Auth/selectors';
import fetchData from '@utils/fetchData';
import postData from '@utils/postData';
import { catchError } from '@utils/sentry';
import { RESCHEDULED_ORDER_ID, localStorageSetItem } from '@utils/localStorageHelpers';
import paymentQueries from './queries';
import { createTransactionRequest, createTransactionSuccess, fetchOrderDetailsRequest, fetchOrderDetailsSuccess, fetchPaymentInvoiceRequest, fetchPaymentInvoiceSuccess, paymentRescheduledSlotRequest, paymentRescheduledSlotSuccess, validateCouponFailed, validateCouponRequest, validateCouponSuccess, } from './slice';
export function* fetchOrderDetailsWorker(action) {
    const { orderId, callback } = action.payload;
    try {
        const queryParams = (yield select(selectQueryParams));
        const oId = orderId || Number(queryParams.order_id);
        const paymentOrderResponse = (yield call(fetchData, {
            queryString: paymentQueries.GET_ORDER_DETAILS_QUERY,
            queryVariables: { order_id: oId },
            queryKey: 'canx_get_order_details',
            forceRefresh: true,
        }));
        const orderDetails = paymentOrderResponse?.[0];
        if (orderDetails) {
            const formattedData = (yield call(extractPaymentDetailsData, orderDetails));
            const data = first(formattedData);
            yield put(fetchOrderDetailsSuccess(data));
            if (callback?.onSuccess) {
                yield call(callback.onSuccess, formattedData);
            }
        }
        else {
            throw new Error('An error occurred while fetching order details.');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Fetch Order Details Worker', error: error, skipToast: true });
        if (callback?.onError) {
            yield call(callback.onError, error);
        }
    }
}
export function* createTransactionWorker(action) {
    const { order_id, callback } = action.payload;
    try {
        const queryParams = (yield select(selectQueryParams));
        const couponDetails = (yield select(selectCouponDetails));
        const updatedQueryParams = queryParams.order_id && queryParams.order_id !== String(order_id)
            ? { ...queryParams, order_id: String(order_id) }
            : queryParams;
        const getOrderId = Number(updatedQueryParams.order_id || queryParams.order_id || order_id);
        if (getOrderId) {
            yield call(localStorageSetItem, RESCHEDULED_ORDER_ID, String(getOrderId));
        }
        const createTransactionResponse = (yield call(postData, {
            queryString: paymentQueries.CREATE_TRANSACTION_MUTATION,
            payload: {
                orderId: getOrderId,
                coupon_code: couponDetails.couponCode,
            },
            spreadPayload: true,
        }));
        const transactionData = createTransactionResponse?.data?.pay_create_transaction;
        if (transactionData) {
            yield put(createTransactionSuccess());
            if (callback?.onSuccess) {
                yield call(callback.onSuccess, transactionData);
            }
        }
        else {
            throw new Error('Transaction creation failed');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Create Transaction Worker', error: error, skipToast: true });
        if (callback?.onError) {
            yield call(callback.onError, error);
        }
    }
}
export function* validateCouponWorker(action) {
    const { order_id, coupon_code, callback } = action.payload;
    try {
        const response = (yield call(postData, {
            queryString: paymentQueries.VALIDATE_COUPON_MUTATION,
            payload: { orderId: order_id, couponCode: coupon_code },
            spreadPayload: true,
        }));
        const validateCouponResponse = response?.data?.pay_validate_coupon;
        if (validateCouponResponse) {
            const formatCouponRes = formatCouponData(validateCouponResponse);
            yield put(validateCouponSuccess(formatCouponRes));
            if (callback?.onSuccess) {
                yield call(callback.onSuccess, validateCouponResponse);
            }
        }
        else {
            throw new Error('Failed to validate coupon.');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Validate Coupon Worker', error: error, skipToast: true });
        yield put(validateCouponFailed());
        if (callback?.onError) {
            yield call(callback.onError, error);
        }
    }
}
export function* paymentRescheduledSlotWorker(action) {
    const { drive_occurrence_guest_id, callback } = action.payload;
    try {
        const response = (yield call(postData, {
            queryString: paymentQueries.PAYMENT_RESCHEDULED_SLOT,
            payload: { driveOccGuestId: drive_occurrence_guest_id },
            spreadPayload: true,
        }));
        const rescheduledApiResponse = response?.data?.canx_reschedule_drive_guest;
        if (rescheduledApiResponse) {
            yield put(paymentRescheduledSlotSuccess(rescheduledApiResponse));
            if (callback?.onSuccess) {
                yield call(callback.onSuccess, rescheduledApiResponse);
            }
        }
        else {
            throw new Error('Failed to validate rescheduled slot data.');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Payment Rescheduled Worker', error: error, skipToast: true });
        if (callback?.onError) {
            yield call(callback.onError, error);
        }
    }
}
export function* fetchPaymentInvoiceWorker(action) {
    const { invoiceData, callback } = action.payload;
    try {
        const paymentInvoiceResponse = yield call(fetchData, {
            queryString: paymentQueries.GET_PAYMENT_INVOICE,
            queryVariables: {
                invoice_id_check: invoiceData.invoiceId ? { _eq: invoiceData.invoiceId } : { _is_null: true },
                invoice_number_check: invoiceData.invoiceNumber ? { _eq: invoiceData.invoiceNumber } : { _is_null: true },
            },
            queryKey: 'pay_invoice',
            forceRefresh: true,
        });
        const paymentInvoice = paymentInvoiceResponse?.[0];
        if (paymentInvoice) {
            yield put(fetchPaymentInvoiceSuccess(paymentInvoice));
            if (callback?.onSuccess) {
                yield call(callback.onSuccess, paymentInvoice);
            }
        }
        else {
            throw new Error('No invoice found.');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Fetch Payment Invoice Worker', error: error, skipToast: true });
        if (callback?.onError) {
            yield call(callback.onError, error);
        }
    }
}
export function* fetchOrderDetailsSagaWatcher() {
    yield debounce(1000, fetchOrderDetailsRequest.type, fetchOrderDetailsWorker);
}
export function* createTransactionSagaWatcher() {
    yield takeLatest(createTransactionRequest.type, createTransactionWorker);
}
export function* validateCouponSagaWatcher() {
    yield takeLatest(validateCouponRequest.type, validateCouponWorker);
}
export function* paymentRescheduledSlotSagaWatcher() {
    yield debounce(200, paymentRescheduledSlotRequest.type, paymentRescheduledSlotWorker);
}
export function* fetchPaymentInvoiceSagaWatcher() {
    yield debounce(1000, fetchPaymentInvoiceRequest.type, fetchPaymentInvoiceWorker);
}
export function* fetchOrderDetailsRootSaga() {
    yield fork(fetchOrderDetailsSagaWatcher);
    yield fork(createTransactionSagaWatcher);
    yield fork(validateCouponSagaWatcher);
    yield fork(paymentRescheduledSlotSagaWatcher);
    yield fork(fetchPaymentInvoiceSagaWatcher);
}
