import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    request: {
        isLoading: false,
        error: null,
    },
    data: {
        answer: null,
        answers: [],
        appState: null,
        editorContent: {},
        mediaFiles: {},
    },
};
export const longFormSlice = createSlice({
    name: 'longForm',
    initialState,
    reducers: {
        longFormStoreAnswer: (state, action) => {
            state.data.answer = action.payload.data;
            // state.data.answers.push(action.payload.data);
        },
        longFormSaveAppState: (_state, _action) => {
            // state.data = action.payload.data;
        },
        longFormSaveEditorContent: (state, action) => {
            state.data.editorContent[action.payload.questionId] = action.payload.content;
        },
        longFormMediaUpload: (state, _action) => {
            state.request = { isLoading: true, error: null };
        },
        longFormMediaUploadSuccess: (state, action) => {
            state.request = { isLoading: false, error: null };
            state.data.answer = action.payload;
        },
        longFormMediaDownload: (state, _action) => {
            state.request = { isLoading: true, error: null };
        },
        longFormMediaDownloadSuccess: (state, action) => {
            state.request = { isLoading: false, error: null };
            state.data.mediaFiles[action.payload.id] = action.payload.resource_url;
        },
    },
});
export const { longFormMediaUpload, longFormMediaUploadSuccess, longFormSaveAppState, longFormStoreAnswer, longFormSaveEditorContent, longFormMediaDownload, longFormMediaDownloadSuccess, } = longFormSlice.actions;
export default longFormSlice.reducer;
