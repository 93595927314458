import { call, debounce, delay, fork, put, select, takeLatest } from '@redux-saga/core/effects';
import { filter, find, get } from 'lodash';
import { fetchNextQuestionById, fetchQuestionsList, fetchTcfMeasure, fetchTestInstance, getAppState, initialize, resetSectionState, saveAnswer, saveAppState, saveBulkAnswer, startAssessment, startAssessmentError, startSection, stopSection, storeAnswer, storeAppState, storeAssessment, storeBulkAnswer, storeNextQuestion, storeQuestionList, storeSection, storeStepDetails, storeStopSection, storeTcfMeasurement, submitSection, updatePreInstructionState, } from '@containers/Assessment/slice';
import { catchError } from '@utils/sentry';
import postData from '@utils/postData';
import { CANX_GET_APP_STATE, CANX_UPSERT_APP_STATE, CREATE_TEST_INSTANCE, GET_ALL_QUESTION, GET_NEXT_QUESTION_BY_ID, GET_STEP_DETAILS, GET_TCF_MEASUREMENT, SAVE_ANSWERS, START_ASSESSMENT, START_SECTION, STOP_SECTION, } from '@containers/Assessment/queries';
import { selectQueryParams } from '@containers/Auth/selectors';
import { getNextQuestionId, getOverallSections, parseBulkSavedAnswer, parseSubmittedAnswer, processStartAssessmentResponse, processStartSectionResponse, } from '@containers/Assessment/helpers';
import { selectAllQuestion, selectAnswers, selectAppState, selectCurrentQuestion, selectNextQuestionId, selectPreInstructionState, selectQuestionIds, selectStartSectionResponse, selectStepDetail, selectTestInstanceId, } from '@containers/Assessment/selectors';
import { getCurrentDate } from '@utils/dateHelpers';
import fetchData from '@utils/fetchData';
import { storeQueryParams } from '@containers/Auth/slice';
import { bulkAnswerSubmitted } from '@utils/mixpanel/mixpanelActions';
import { stopHeartBeat } from '@containers/App/types';
import { ASSESSMENT_PRE_REGISTRATION_FORM, localStorageGetItem } from '@utils/localStorageHelpers';
export function* initializeWorker() {
    try {
        const testInstanceId = (yield select(selectTestInstanceId));
        const queryParams = (yield select(selectQueryParams));
        const formSubmitted = localStorageGetItem(ASSESSMENT_PRE_REGISTRATION_FORM);
        if (queryParams?.step_id && formSubmitted !== 'true') {
            yield call(fetchStepDetailsWorker, { step_id: Number(queryParams?.step_id) });
            const step = yield select(selectStepDetail);
            const { hasPreform } = yield select(selectPreInstructionState);
            const formId = step?.data?.canx_get_step_details?.step_forms?.[0]?.form?.id;
            yield put(updatePreInstructionState({
                hasPreform: !!formId,
                preformSubmitted: hasPreform,
                formId: formId,
            }));
            if (!formId && testInstanceId) {
                yield put(startAssessment({ instance_id: testInstanceId }));
            }
            else {
                throw Error('No test_instance_id found in queryParams');
            }
        }
        else if (testInstanceId) {
            yield put(startAssessment({ instance_id: testInstanceId }));
        }
        else {
            throw Error('No test_instance_id found in queryParams');
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'Assessment initialize Worker',
            error: error,
        });
    }
}
export function* fetchStepDetailsWorker({ step_id }) {
    try {
        if (step_id) {
            const response = (yield call(postData, {
                queryString: GET_STEP_DETAILS,
                payload: {
                    step_id: step_id,
                },
                spreadPayload: true,
            }));
            if (response?.data?.canx_get_step_details) {
                yield put(storeStepDetails(response));
            }
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'Fetching step details failed.',
            error: error,
        });
    }
}
function* startAssessmentWorker() {
    try {
        const testInstanceId = (yield select(selectTestInstanceId));
        const response = (yield call(postData, {
            queryString: START_ASSESSMENT,
            payload: {
                instance_id: testInstanceId,
            },
        }));
        const result = response?.data?.canx_start_assessment[0];
        if (result?.success) {
            const processedData = (yield call(processStartAssessmentResponse, result.data));
            yield put(storeAssessment(processedData));
        }
        else {
            const err = Array.isArray(result?.error_message) ? result?.error_message?.[0] : result?.error_message;
            throw Error(`Failed to start assessment: ${err}`);
        }
    }
    catch (error) {
        const err = error;
        yield call(catchError, {
            title: 'startAssessmentWorker',
            error: err,
        });
        yield put(startAssessmentError({ error: err && err?.message }));
    }
}
export function* startSectionWorker({ payload }) {
    try {
        const testInstanceId = (yield select(selectTestInstanceId));
        const response = (yield call(postData, {
            queryString: START_SECTION,
            payload: { instance_id: testInstanceId },
        }));
        const result = response?.data?.canx_start_next_section[0];
        if (result?.success) {
            if (result.data?.id) {
                yield call(getAppStateWorker, {
                    payload: {
                        section_id: result.data?.id,
                    },
                    type: getAppState.type,
                });
            }
            const appState = (yield select(selectAppState));
            const processedData = (yield call(processStartSectionResponse, result.data, appState));
            if (!processedData.assessmentSection) {
                yield call(payload.callback.onSuccess, {
                    redirectTo: '/assessment/result',
                });
            }
            else if (!processedData.next_question_id) {
                yield put(storeSection(processedData));
                yield call(payload.callback.onSuccess, {
                    redirectTo: '/assessment/submit',
                });
            }
            else {
                yield put(storeSection(processedData));
                yield call(fetchQuestionsListWorker);
                if (payload.callback.onSuccess) {
                    yield call(payload.callback.onSuccess);
                }
            }
        }
        else {
            const err = Array.isArray(result?.error_message) ? result?.error_message?.[0] : result?.error_message;
            throw Error(`Failed to start section: ${err}`);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'startSectionWorker',
            error: error,
        });
        if (payload?.callback?.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* getNextSectionWorker({ payload }) {
    try {
        const testInstanceId = (yield select(selectTestInstanceId));
        const response = (yield call(postData, {
            queryString: START_SECTION,
            payload: { instance_id: testInstanceId },
        }));
        const result = response?.data?.canx_start_next_section[0];
        if (result?.success) {
            const processedData = (yield call(processStartSectionResponse, result.data));
            if (processedData.assessmentSection) {
                yield put(resetSectionState());
                yield call(payload.callback.onSuccess, {
                    redirectTo: '/assessment/invite',
                });
            }
            else if (window.self !== window.top) {
                window.parent.postMessage({ exit: true, discardExitConfirmation: true }, '*');
            }
            else {
                yield call(payload.callback.onSuccess, {
                    redirectTo: '/assessment/feedback',
                });
            }
        }
        else {
            const err = Array.isArray(result?.error_message) ? result?.error_message?.[0] : result?.error_message;
            throw Error(`Failed to start section: ${err}`);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'startSectionWorker',
            error: error,
        });
        if (payload?.callback?.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* fetchNextQuestionByIdWorker({ payload }) {
    try {
        const nextQuestionId = (yield select(selectNextQuestionId));
        if (!nextQuestionId) {
            yield put({ type: stopHeartBeat });
            if (payload.callback.onSuccess) {
                yield call(payload.callback.onSuccess, { redirectTo: '/assessment/submit' });
            }
        }
        else {
            let result;
            const questions = (yield select(selectAllQuestion));
            if (questions?.length) {
                const question = nextQuestionId ? find(questions, { id: nextQuestionId }) : null;
                if (question) {
                    result = {
                        success: true,
                        data: [question],
                    };
                }
                else {
                    result = {
                        error_message: 'Question not found in question list',
                    };
                }
            }
            else {
                const startSectionResponse = (yield select(selectStartSectionResponse));
                const response = (yield call(postData, {
                    queryString: GET_NEXT_QUESTION_BY_ID,
                    payload: {
                        question_id: nextQuestionId,
                        section_id: startSectionResponse?.id,
                    },
                    spreadPayload: true,
                }));
                result = response?.data?.ae_get_next_question;
            }
            if (result?.success) {
                const questionIds = (yield select(selectQuestionIds));
                const question = result?.data?.[0];
                const nextQuestionId = getNextQuestionId(questionIds, question?.id);
                yield put(storeNextQuestion({ data: result.data?.[0], next_question_id: nextQuestionId }));
                if (payload.callback.onSuccess) {
                    yield call(payload.callback.onSuccess);
                }
            }
            else {
                const err = Array.isArray(result?.error_message)
                    ? result?.error_message?.[0]
                    : result?.error_message;
                throw Error(`Failed to start section: ${err}`);
            }
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'fetchNextQuestionByIdWorker',
            error: error,
        });
        if (payload?.callback?.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* fetchQuestionsListWorker() {
    try {
        const testInstanceId = (yield select(selectTestInstanceId));
        const startSectionResponse = (yield select(selectStartSectionResponse));
        const response = (yield call(postData, {
            queryString: GET_ALL_QUESTION,
            payload: {
                instance_id: testInstanceId,
                section_id: startSectionResponse?.id,
            },
            spreadPayload: true,
        }));
        if (response) {
            yield put(storeQuestionList({ data: response?.data?.ae_get_question_list?.data }));
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'fetchQuestionsListWorker',
            error: error,
        });
    }
}
export function* saveAnswerWorker({ payload, }) {
    try {
        const currentQuestion = (yield select(selectCurrentQuestion));
        const startSectionResponse = (yield select(selectStartSectionResponse));
        const questions = (yield select(selectAllQuestion));
        let result;
        if (questions) {
            result = parseSubmittedAnswer(payload, currentQuestion, startSectionResponse);
        }
        else {
            const response = (yield call(postData, {
                queryString: SAVE_ANSWERS,
                payload: {
                    question_id: currentQuestion.id,
                    section_id: startSectionResponse.id,
                    answered_at: getCurrentDate()?.toUTC().toISO(),
                    choice: payload.data.choice,
                },
            }));
            result = response?.data?.ae_save_answer[0];
        }
        if (result?.success) {
            yield put(storeAnswer(result.data));
            if (!(typeof result?.data?.is_correct_choice === 'boolean')) {
                yield put(fetchNextQuestionById({ callback: payload.callback }));
            }
            else if (payload.callback.onSuccess) {
                yield call(payload.callback.onSuccess);
            }
        }
        else {
            const err = Array.isArray(result?.error_message) ? result?.error_message?.[0] : result?.error_message;
            throw Error(`Failed to save answer:  ${err}`);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'saveAnswerWorker',
            error: error,
        });
        if (payload?.callback?.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* saveBulkAnswerWorker({ payload }) {
    try {
        const allAnswers = (yield select(selectAnswers));
        const answers = (yield call(filter, allAnswers, (obj) => obj.id === 'has_to_save'));
        const eventOption = {
            Type: 'Manual',
        };
        bulkAnswerSubmitted(eventOption);
        if (answers.length) {
            const formattedAnswerObject = answers.map((item) => ({
                question_id: item.question_id,
                section_id: item.section_id,
                answered_at: item.answered_at,
                choice: item.choice,
            }));
            const response = (yield call(postData, {
                queryString: SAVE_ANSWERS,
                payload: formattedAnswerObject,
            }));
            const parseBulkSavedAnswerResponse = (yield call(parseBulkSavedAnswer, response, allAnswers));
            if (payload.callback.onSuccess && parseBulkSavedAnswerResponse) {
                yield put(storeBulkAnswer(parseBulkSavedAnswerResponse.formattedAnswers));
                yield call(payload.callback.onSuccess, parseBulkSavedAnswerResponse);
            }
        }
        else {
            yield put(stopSection({ callback: payload.callback }));
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'saveBulkAnswerWorker',
            error: error,
        });
        if (payload?.callback?.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* stopSectionWorker({ payload, }) {
    try {
        const startSectionResponse = (yield select(selectStartSectionResponse));
        const response = (yield call(postData, {
            queryString: STOP_SECTION,
            payload: {
                section_id: startSectionResponse.id,
            },
        }));
        const result = response?.data?.ae_stop_section[0];
        if (result?.success) {
            yield put(storeStopSection({ startSectionResponse: result.data }));
            if (payload.callback.onSuccess) {
                if (payload?.async) {
                    yield call(payload.callback.onSuccess, { redirectTo: '/assessment/feedback' });
                }
                else {
                    yield call(payload.callback.onSuccess, { redirectTo: '/assessment/result' });
                }
            }
        }
        else {
            const err = Array.isArray(result?.error_message) ? result?.error_message?.[0] : result?.error_message;
            throw Error(`Failed to stop section:  ${err}`);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'stopSectionWorker',
            error: error,
        });
    }
}
export function* fetchTcfMeasureWorker({ payload: { callback }, }) {
    try {
        const testInstanceId = (yield select(selectTestInstanceId));
        const response = (yield call(fetchData, {
            queryString: GET_TCF_MEASUREMENT,
            queryKey: 'ae_get_instance_score',
            queryVariables: { test_instance_id: testInstanceId },
            forceRefresh: true,
            context: {},
        }));
        const result = response[0];
        const overallData = (yield call(getOverallSections, result.data));
        if (!overallData.length) {
            yield delay(5000);
            yield put(fetchTcfMeasure({ callback }));
        }
        else if (callback.onSuccess) {
            yield put(storeTcfMeasurement(overallData));
            yield call(callback.onSuccess);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'fetchTcfMeasureWorker',
            error: error,
        });
    }
}
export function* saveAppStateWorker({ payload, }) {
    try {
        const { id } = (yield select(selectStartSectionResponse));
        const answers = (yield select(selectAnswers));
        const currentQuestion = (yield select(selectCurrentQuestion));
        const nextQuestionId = yield select(selectNextQuestionId);
        const formattedPayload = {
            section_id: id,
            state: [
                {
                    remaining_time: payload?.data?.remaining_time,
                    answers,
                    currentQuestionId: currentQuestion?.id,
                    nextQuestionId,
                },
            ],
        };
        const response = (yield call(postData, {
            queryString: CANX_UPSERT_APP_STATE,
            payload: formattedPayload,
        }));
        const result = response?.data?.canx_upsert_app_state[0];
        if (result?.success) {
            yield put(storeAppState(result?.data));
        }
        else {
            yield call(catchError, {
                title: 'saveAppStateWorker',
                error: Error('Failed to save the appState to server'),
                extraScope: { key: 'appState', value: JSON.stringify(formattedPayload) },
                skipToast: true,
            });
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'saveAppStateWorker',
            error: error,
        });
    }
}
export function* createTestInstanceWorker({ payload, }) {
    try {
        const testInstanceId = (yield select(selectTestInstanceId));
        if (testInstanceId) {
            const response = (yield call(postData, {
                queryString: CREATE_TEST_INSTANCE,
                payload: {
                    test_instance_id: testInstanceId,
                    reattempt: true,
                },
                spreadPayload: true,
            }));
            const result = response?.data?.canx_create_test_instance[0];
            if (result?.success) {
                const queryParams = (yield select(selectQueryParams));
                const test_instance_id = result?.data?.test_instance_id;
                if (test_instance_id) {
                    yield put(storeQueryParams({ ...queryParams, test_instance_id: String(test_instance_id) }));
                    yield put(initialize({}));
                    if (payload.callback.onSuccess) {
                        yield call(payload.callback.onSuccess);
                    }
                }
                else {
                    throw Error('createTestInstanceWorker: Test instance id not found in the reattempt response');
                }
            }
            else {
                const err = Array.isArray(result?.error_message)
                    ? result?.error_message?.[0]
                    : result?.error_message;
                throw Error(`Failed to createTestInstance: ${err}`);
            }
        }
        else {
            throw Error('createTestInstance: No test instance id found');
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'createTestInstance',
            error: error,
        });
        if (payload?.callback?.onError)
            yield call(payload.callback.onError, error);
    }
}
export function* getAppStateWorker({ payload, }) {
    try {
        if (payload?.section_id) {
            const response = (yield call(fetchData, {
                queryString: CANX_GET_APP_STATE,
                queryVariables: {
                    section_id: Number(payload?.section_id),
                },
            }));
            const result = get(response, 'canx_get_app_state.0', null);
            if (result?.section_id) {
                yield put(storeAppState(result));
                if (payload?.callback?.onSuccess) {
                    yield call(payload?.callback.onSuccess);
                }
            }
        }
        else {
            throw Error('No Section id found');
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'getAppStateInstance',
            error: error,
        });
    }
}
export function* initializeWatcher() {
    yield debounce(1000, initialize.type, initializeWorker);
}
export function* startAssessmentWatcher() {
    yield takeLatest(startAssessment.type, startAssessmentWorker);
}
export function* startSectionWatcher() {
    yield debounce(1000, startSection.type, startSectionWorker);
}
export function* getNextSectionWatcher() {
    yield takeLatest(submitSection.type, getNextSectionWorker);
}
export function* fetchNextQuestionByIdWatcher() {
    yield takeLatest(fetchNextQuestionById.type, fetchNextQuestionByIdWorker);
}
export function* fetchQuestionsListWatcher() {
    yield takeLatest(fetchQuestionsList.type, fetchQuestionsListWorker);
}
export function* saveAnswerWatcher() {
    yield takeLatest(saveAnswer.type, saveAnswerWorker);
}
export function* stopSectionWatcher() {
    yield takeLatest(stopSection.type, stopSectionWorker);
}
export function* fetchTcfMeasureWatcher() {
    yield debounce(1000, fetchTcfMeasure.type, fetchTcfMeasureWorker);
}
export function* saveAppStateWatcher() {
    yield debounce(2000, saveAppState.type, saveAppStateWorker);
}
export function* createTestInstanceWatcher() {
    yield takeLatest(fetchTestInstance.type, createTestInstanceWorker);
}
export function* getAppStateWatcher() {
    yield takeLatest(getAppState.type, getAppStateWorker);
}
export function* saveBulkAnswerWatcher() {
    yield debounce(1000, saveBulkAnswer.type, saveBulkAnswerWorker);
}
export function* assessmentRootSaga() {
    yield fork(initializeWatcher);
    yield fork(startAssessmentWatcher);
    yield fork(startSectionWatcher);
    yield fork(fetchNextQuestionByIdWatcher);
    yield fork(fetchQuestionsListWatcher);
    yield fork(saveAnswerWatcher);
    yield fork(stopSectionWatcher);
    yield fork(fetchTcfMeasureWatcher);
    yield fork(saveAppStateWatcher);
    yield fork(createTestInstanceWatcher);
    yield fork(getNextSectionWatcher);
    yield fork(getAppStateWatcher);
    yield fork(saveBulkAnswerWatcher);
}
