import { catchError } from '@utils/sentry';
import { call } from 'redux-saga/effects';
import { runSaga } from 'redux-saga';
import { triggerToast } from '@components/base/Notification';
export function* handleSagaSuccess({ callback, response, }) {
    try {
        if (callback) {
            yield call(callback, response ?? {});
        }
    }
    catch (error) {
        triggerToast({
            message: {
                title: `Error in handling saga success response`,
                summary: error.message || `Unknown error occurred: ${error}`,
            },
            variant: 'danger',
        });
    }
}
export function* handleSagaError({ callback, error, title = 'Saga Title Error', skipToast = false, }) {
    try {
        if (callback) {
            yield call(callback, error);
        }
        yield call(catchError, { title, error, skipToast });
    }
    catch (nestedError) {
        triggerToast({
            message: {
                title: `Error in handling saga error`,
                summary: nestedError.message || `Unknown error occurred while processing error handling: ${nestedError}`,
            },
            variant: 'danger',
        });
    }
}
const testSagaExecution = async (saga, initialAction, mockResponse) => {
    const dispatched = [];
    await runSaga({
        dispatch: (action) => dispatched.push(action),
        getState: () => mockResponse || {},
    }, saga, initialAction).toPromise();
    return dispatched;
};
export default testSagaExecution;
