export const mixpanelEvents = {
    // Timer Events
    CANDIDATE_TIMER_HIDE: 'candidate_timer_hide',
    CANDIDATE_TIMER_UNHIDE: 'candidate_timer_unhide',
    // Instruction Events
    CANDIDATE_INSTRUCTION_PAGE_LANDED: 'candidate_instruction_page_landed',
    INSTRUCTIONS_CANDIDATE_CLICK_GET_STARTED: 'instructions_candidate_click_get_started',
    INSTRUCTIONS_CANDIDATE_CLICK_TERMS_CONDITIONS: 'instructions_candidate_click_terms_conditions',
    INSTRUCTIONS_CANDIDATE_CLICK_PRIVACY_POLICY: 'instructions_candidate_click_privacy_policy',
    // Authentication and Login Events
    CANDIDATE_LOGIN_SOCIAL_MEDIA_INITIATED: 'candidate_login_social_media_initiated',
    CANDIDATE_LOGIN_SOCIAL_MEDIA_SUCCESS: 'candidate_login_social_media_success',
    CANDIDATE_LOGIN_OTP_REQUESTED: 'candidate_login_otp_requested',
    CANDIDATE_LOGIN_SOCIAL_MEDIA_LOGIN_FAILED: 'candidate_login_social_media_login_failed',
    CANDIDATE_LOGIN_OTP_FILLED_LOGIN_SUCCESS: 'candidate_login_otp_filled_login_success',
    CANDIDATE_AUTHENTICATED: 'candidate_authenticated',
    CANDIDATE_LOGIN_CLICK: 'candidate_login_click',
    CANDIDATE_REGISTRATION_CONTINUE: 'candidate_registration_continue',
    // Assessment and Answer Events
    CANDIDATE_ASSESSMENT_SUBMITTED: 'candidate_assessment_submitted',
    CANDIDATE_ANSWER_SELECTED: 'candidate_answer_selected',
    CANDIDATE_NEXT_QUESTION_CLICKED: 'candidate_next_question_clicked',
    CANDIDATE_SCORE_PAGE_LOAD: 'candidate_score_page_load',
    CANDIDATE_SCORE_PAGE_NEXT_CLICKED: 'candidate_score_page_next_clicked',
    // Async Events
    STOP_RECORDING_CLICK: 'candidate_async_stop_recording_click',
    NEXT_ASYNC_QUESTION_CLICK: 'candidate_async_next_question_click',
    START_RECORDING_CLICK: 'candidate_async_start_recording_click',
    CANDIDATE_ASYNC_PERMISSION_ALLOWED: 'candidate_async_permission_allowed',
    CANDIDATE_ASYNC_TOGGLE_PREVIEW_CLICK: 'candidate_async_toggle_preview_click',
    // Payment Events
    CANDIDATE_PAYMENT_PROCEED: 'candidate_payment_proceed',
    CANDIDATE_COUPON_CODE_APPLY: 'candidate_coupon_code_apply',
    // Scheduling and Slot Events
    CANDIDATE_SLOT_BOOKING_CONFIRM: 'candidate_slot_booking_confirm',
    CANDIDATE_SCHEDULE_CLICK: 'candidate_schedule_click',
    CANDIDATE_SLOT_BOOKING_PAGE_LANDED: 'candidate_slot_booking_page_landed',
    CANDIDATE_SLOT_CONFIRMED: 'candidate_slot_confirmed',
    CANDIDATE_SLOT_RESCHEDULED: 'candidate_slot_rescheduled',
    CANDIDATE_EARLY_BIRD_PAGE_LANDED: 'candidate_early_bird_page_landed',
    CANDIDATE_RSVP_ACCEPT_PROPOSED_TIME: 'candidate_rsvp_accept_proposed_time',
    CANDIDATE_RSVP_DECLINE_PROPOSED_TIME: 'candidate_rsvp_decline_proposed_time',
    CANDIDATE_RSVP_PROPOSE_ALTERNATIVE_TIME: 'candidate_rsvp_propose_alternate_time',
    CANDIDATE_INTERVIEW_EARLY_BIRD_PAGE_LANDED: 'candidate_interview_early_bird_page_landed',
    CANDIDATE_INTERVIEW_JOIN: 'candidate_interview_join',
    // Lobby Events
    CANDIDATE_LOBBY_WAIT_PAGE_LANDED: 'candidate_lobby_wait_page_landed',
    CANDIDATE_LOBBY_TO_ALMOST_THERE_TRANSITION: 'candidate_lobby_to_almost_there_transition',
    CANDIDATE_ALMOST_THERE_TO_READY_TRANSITION: 'candidate_almost_there_to_ready_transition',
    CANDIDATE_MISSED_SLOT_PAGE_LANDED: 'candidate_missed_slot_page_landed',
    CANDIDATE_BACK_TO_QUEUE_CLICKED: 'candidate_back_to_queue_clicked',
    // Feedback Events
    TASK_TAKER_FEEDBACK_PAGE: 'task_taker_feedback_page',
    FORM_CANDIDATE_LAND_THANK_YOU_PAGE: 'form_candidate_land_thank_you_page',
    CANDIDATE_FEEDBACK_SUBMIT: 'candidate_feedback_submit',
    CANDIDATE_FEEDBACK_SKIP: 'candidate_feedback_skip',
    CANDIDATE_RSVP_PROPOSE_TIME_LAND: 'candidate_rsvp_proposetime_land',
    CANDIDATE_RSVP_PROPOSE_TIME_CONFIRM: 'candidate_rsvp_proposetime_confirm',
    CANDIDATE_RSVP_DECLINE_LAND: 'candidate_rsvp_decline_land',
    CANDIDATE_RSVP_DECLINE_FLOW_PROPOSE_TIME_CLICK: 'candidate_rsvp_declineflow_proposetime_click',
    CANDIDATE_RSVP_DECLINE_CONFIRM: 'candidate_rsvp_decline_confirm',
    CANDIDATE_RSVP_DECLINE_FLOW_PROPOSE_TIME_CONFIRM: 'candidate_rsvp_declineflow_propostime_confirm',
    CANDIDATE_RSVP_DECLINE_FLOW_PROPOSE_TIME_LAND: 'candidate_rsvp_declineflow_proposetime_land',
    CANDIDATE_RSVP_CONFIRM: 'candidate_rsvp_confirm',
    //FaceAuth Events
    CANDIDATE_FACEAUTH_ALLOW: 'candidate_faceauth_allow',
    CANDIDATE_FACEAUTH_BLOCK: 'candidate_faceauth_block',
    CANDIDATE_FACEAUTH_CAPTURE: 'candidate_faceauth_capture',
    CANDIDATE_FACEAUTH_RETAKE: 'candidate_faceauth_retake',
    CANDIDATE_FACEAUTH_SUBMIT: 'candidate_faceauth_submit',
    CANDIDATE_FACEAUTH_NEXTSTEP: 'candidate_faceauth_nextstep',
};
