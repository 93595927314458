export const growthBookFeatureFlags = {
    PLATFORM_LOGO_URL: 'logo_url',
    ALLOW_ASSESSMENT_REATTEMPT: 'cx_allow_assessment_reattempt',
    DISPLAY_ASSESSMENT_SCORE: 'display_assessment_score',
    SHOW_ANSWER_FEEDBACK: 'show_answer_feedback',
    MIX_PANEL_ENABLED: 'mixpanel_enabled',
    DISABLED_COPY_PASTE: 'cx3_copy_paste',
    WATER_MARK_ENABLED: 'watermark_content',
    ALLOW_QUESTION_RETAKE: 'allow_question_retake',
    RECORDING_PLAYBACK_ENABLED: 'recording_playback_enabled',
    SKIP_PAYMENT: 'skip_payment',
    DRIVE_REGISTRATION_FORM: 'drive_registration_form',
    SCH_CERTIFICATION_USE_CASE: 'sch_certification_use_case',
    CANDIDATE_CONSENT_LIST: 'candidate_consent_list',
    CANDIDATE_AUTH_ENABLED: 'candidate_auth_is',
    CX_TERMS_PRIVACY_LINKS: 'cx_terms_privacy_links',
    CX_DRIVE_IN_V2: 'cx_drive_in_v2',
    FACE_AUTH_FACE_DETECTION: 'face_auth_face_detection',
    CANDIDATE_POST_COMPLETION_REDIRECT: 'candidate_post_completion_redirect',
    CONSENT_ENABLED: 'consent_enabled', // non existing flag
    CONSENT_ALWAYS_REQUIRED: 'consent_always_required', // non existing flag
    EXIT_URL: 'exit_url',
};
