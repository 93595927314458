import { call, debounce, fork, put, retry, select, takeLatest } from 'redux-saga/effects';
import { catchError } from '@utils/sentry';
import { getCurrentDate } from '@utils/dateHelpers';
import postData from '@utils/postData';
import { selectCurrentQuestion, selectStartSectionResponse } from '@containers/Assessment/selectors';
import { SAVE_ANSWERS } from '../queries';
import { DOWNLOAD_MEDIA_FILE, UPLOAD_MEDIA_FILE } from './queries';
import { longFormMediaDownload, longFormMediaDownloadSuccess, longFormMediaUpload, longFormMediaUploadSuccess, longFormSaveEditorContent, longFormStoreAnswer, } from './slice';
import { fetchNextQuestionById, storeAnswer } from '../slice';
import fetchData from '@utils/fetchData';
export function* longFormSaveAnswerWorker({ payload, }) {
    try {
        const currentQuestion = (yield select(selectCurrentQuestion));
        const startSectionResponse = (yield select(selectStartSectionResponse));
        const formattedPayload = [
            {
                question_id: currentQuestion.id,
                section_id: startSectionResponse.id,
                answered_at: getCurrentDate()?.toUTC()?.toISO(),
                text_response: payload.data.text_response,
            },
        ];
        const response = (yield retry(3, 20, postData, {
            queryString: SAVE_ANSWERS,
            payload: formattedPayload,
        }));
        const result = response?.data?.ae_save_answer[0];
        if (result?.success) {
            //  yield put(longFormStoreAnswer({ data: result.data as AnswersOutput }));
            yield put(storeAnswer(result.data));
            // yield put(saveAppState({ data: { remaining_time: 0 } }));
            yield put(fetchNextQuestionById({ callback: payload.callback }));
        }
        else {
            const err = Array.isArray(result?.error_message) ? result?.error_message?.[0] : result?.error_message;
            throw Error(`Failed to save long form answer:  ${err}`);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'longFormSaveAnswerWorker',
            error: error,
            skipToast: true,
        });
        if (payload?.callback?.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* longFormStoreEditorContentWorker({ payload, }) {
    try {
        const currentQuestion = (yield select(selectCurrentQuestion));
        const startSectionResponse = (yield select(selectStartSectionResponse));
        const formattedAppStorePayload = [
            {
                question_id: currentQuestion.id,
                section_id: startSectionResponse.id,
                answered_at: getCurrentDate()?.toUTC()?.toISO(),
                text_response: {
                    doc: payload.content,
                },
            },
        ];
        yield put(storeAnswer(formattedAppStorePayload));
    }
    catch (error) {
        console.error('Error in longFormStoreEditorContentWorker:', error);
        yield call(catchError, {
            title: 'longFormStoreEditorContentWorker',
            error: error,
            skipToast: true,
        });
    }
}
export function* longFormMediaUploadWorker({ payload }) {
    try {
        const response = yield call(postData, {
            queryString: UPLOAD_MEDIA_FILE,
            payload: payload.data,
            spreadPayload: true,
        });
        const result = response.data.file_upload_file;
        if (result) {
            yield put(longFormMediaUploadSuccess(result));
            payload.callback?.(true, result);
        }
        else {
            throw new Error('Something went wrong', { cause: 'Long form media upload failed' });
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'longFormMediaUploadWorker',
            error: error,
            skipToast: true,
        });
    }
}
export function* longFormMediaDownloadWorker({ payload, }) {
    try {
        const response = yield call(fetchData, {
            queryString: DOWNLOAD_MEDIA_FILE,
            queryVariables: { file_id: payload.file_id },
            queryKey: 'file_download_file',
            forceRefresh: true,
        });
        if (response) {
            yield put(longFormMediaDownloadSuccess(response));
            if (payload.callback?.onSuccess) {
                yield call(payload.callback.onSuccess, response);
            }
        }
        else {
            throw new Error('Something went wrong', { cause: 'Long form media download failed' });
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'longFormMediaDownloadWorker',
            error: error,
            skipToast: true,
        });
    }
}
export function* longFormSaveAnswerWatcher() {
    yield takeLatest(longFormStoreAnswer.type, longFormSaveAnswerWorker);
}
function* watchLongFormEditorContent() {
    yield takeLatest(longFormSaveEditorContent.type, longFormStoreEditorContentWorker);
}
export function* longFormMediaUploadWatcher() {
    yield debounce(1000, longFormMediaUpload.type, longFormMediaUploadWorker);
}
export function* longFormMediaDownloadWatcher() {
    yield takeLatest(longFormMediaDownload.type, longFormMediaDownloadWorker);
}
export function* longFormAnswerRootSaga() {
    yield fork(longFormSaveAnswerWatcher);
    yield fork(longFormMediaUploadWatcher);
    yield fork(watchLongFormEditorContent);
    yield fork(longFormMediaDownloadWatcher);
}
