import { eventChannel } from 'redux-saga';
import client from './apollo';
import { catchError } from './sentry';
export function createApolloSubscriptionChannel({ query, variables, }) {
    return eventChannel((emit) => {
        // Establish the Apollo subscription
        const subscription = client.subscribe({ query, variables }).subscribe({
            next: (response) => {
                // console.log(response, '::receiving subscription');
                // Emit the received data
                emit({ data: response?.data });
            },
            error: (error) => {
                catchError({
                    error,
                    title: 'Apollo Subscription Error',
                    // skipToast: false,
                    extraScope: {
                        key: 'subscription',
                        value: 'failed',
                    },
                });
                // Emit the error to the subscriber
                emit({ error });
            },
        });
        // Clean up the subscription when the channel is closed
        return () => {
            subscription.unsubscribe();
        };
    });
}
