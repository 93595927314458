import client from '@utils/apollo';
import { processQuery } from '@utils/processQuery';
export const makeRequest = async ({ variables, queryString, key, forceRefresh = false, context = {} }) => {
    const { data } = await client.query({
        query: queryString,
        variables,
        fetchPolicy: forceRefresh ? 'network-only' : 'cache-first',
        context: {
            ...context,
        },
    });
    return key ? data[key] : data;
};
const fetchData = async ({ queryString, queryKey, queryVariables = {}, forceRefresh, context }) => {
    if (!queryString) {
        return [];
    }
    const graphQuery = processQuery(queryString);
    return makeRequest({
        variables: {
            ...queryVariables,
        },
        key: queryKey,
        queryString: graphQuery,
        forceRefresh,
        context,
    });
};
export default fetchData;
