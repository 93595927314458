export const getConsentStep = (candidate) => {
    let state = {
        redirectToRegistration: true,
        redirectForward: false,
    };
    if (candidate?.is_tnc_accepted) {
        state = {
            redirectToRegistration: false,
            redirectForward: true, // set this to false is consent is required to be render always
        };
    }
    else if (candidate?.country) {
        state = {
            redirectToRegistration: false,
            redirectForward: false,
        };
    }
    return state;
};
export const getConsentCompletion = (candidate) => {
    // const consentCountryList: ConsentCountryType = growthBook.getFeatureValue(
    //   growthBookFeatureFlags.candidate_consent_list,
    // );
    // Have to check is consent required base on country selection
    const state = getConsentStep(candidate);
    // have to return true if consent is completed
    // have to return true if consent is not required. means consent is disable in growthbook
    return !state.redirectToRegistration || state.redirectForward;
};
