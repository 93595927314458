import { call, debounce, fork, put, take, takeLatest } from 'redux-saga/effects';
import { createHeartbeatWorker } from '@containers/App/HeartBeatSaga';
import fetchData from '@utils/fetchData';
import postData from '@utils/postData';
import { handleSagaError, handleSagaSuccess } from '@utils/sagaHelper';
import { catchException, processSagaError } from '@utils/catchException';
import { createApolloSubscriptionChannel } from '@utils/apolloEvent';
import { areValidValues } from '@utils/commonHelperUtils';
import { RBS_IN_QUERY, localStorageSetItem } from '@utils/localStorageHelpers';
import driveQueries from './queries';
import { cancelDriveOccurrenceGuestRequest, cancelDriveOccurrenceGuestSuccess, confirmDriveSlotRequest, confirmDriveSlotSuccess, driveDetailsSuccess, driveOccurrenceDetailsSuccess, fetchDriveDetails, fetchDriveOccurrenceDetails, heartBeatStatusRequest, subscribeLobbyDriveScheduleDetailsSuccess, subscribeLobbyDriveScheduleRequest, updateDriveMemberStatusRequest, updateDriveMemberStatusSuccess, } from './slice';
import { isSchDriveLobbyMeeting } from './validateSagaResponse';
import { createMockMeetingData } from './__mocks___/driveMeeting';
import { normalizeDriveOccurrenceGuestResponse } from './DriveHelper';
export function* fetchDriveOccurrenceGuestWorker(action) {
    const { driveOccurrenceGuestId, callback } = action.payload;
    try {
        const response = (yield call(fetchData, {
            queryString: driveQueries.GET_DRIVE_OCCURRENCE_GUEST,
            queryVariables: { driveOccurrenceGuestId },
            queryKey: 'canx_get_drive_occurrence_guest',
            forceRefresh: true,
        }));
        const normalizedDriveOccResponse = normalizeDriveOccurrenceGuestResponse(response);
        if (normalizedDriveOccResponse) {
            yield put(driveDetailsSuccess(normalizedDriveOccResponse));
        }
        else {
            throw catchException.apiError('Normalized response is null for drive canx_get_drive_occurrence_guest', {
                driveOccurrenceGuestId,
            });
        }
        yield call(handleSagaSuccess, {
            callback: callback?.onSuccess,
            response: normalizedDriveOccResponse,
        });
    }
    catch (error) {
        const processedError = processSagaError(error, 'Error in Fetch Drive Occurrence Guest Worker');
        yield call(handleSagaError, {
            callback: callback?.onError,
            error: processedError,
            title: 'Fetch Drive Details Worker',
            skipToast: true,
        });
    }
}
export function* confirmDriveSlotWorker(action) {
    const { driveSlotPayloadData, callback } = action.payload;
    const { drive_id, from, to, driveOccurrenceGuestId } = driveSlotPayloadData;
    try {
        const response = (yield call(postData, {
            queryString: driveQueries.CONFIRM_DRIVE_MEETING_SLOT,
            payload: { drive_id, from, to, driveOccurrenceGuestId },
            spreadPayload: true,
        }));
        const confirmDriveResponse = response?.data?.sch_confirm_drive_slot?.[0];
        if (confirmDriveResponse.success) {
            yield put(confirmDriveSlotSuccess());
            if (confirmDriveResponse?.data?.id) {
                // Dispatch action to trigger `fetchDriveOccurrenceGuestWorker`
                yield put({
                    type: fetchDriveDetails.type,
                    payload: {
                        driveOccurrenceGuestId: confirmDriveResponse.data.id,
                        callback: {
                            *onSuccess() {
                                yield call(handleSagaSuccess, {
                                    callback: callback?.onSuccess,
                                    response: confirmDriveResponse,
                                });
                            },
                            *onError(error) {
                                yield call(handleSagaError, {
                                    callback: callback?.onError,
                                    error,
                                    title: 'Fetch Drive Details Error',
                                });
                            },
                        },
                    },
                });
            }
            else {
                throw catchException.validationError('Missing driveOccurrenceGuestId in confirmDriveResponse', {
                    response: confirmDriveResponse,
                });
            }
        }
        else {
            throw catchException.apiError(confirmDriveResponse.error_message || 'Failed to confirm drive slot.', {
                response: confirmDriveResponse,
            });
        }
    }
    catch (error) {
        const processedError = processSagaError(error, 'Error in Confirm Drive Slot Worker');
        yield call(handleSagaError, {
            callback: callback?.onError,
            error: processedError,
            title: processedError.message,
            skipToast: true,
        });
    }
}
export function* fetchDriveOccurrenceDetailsSagaWorker(action) {
    const { driveOccGuestId, from, to, callback } = action.payload;
    try {
        const payload = { driveOccGuestId };
        const isRBSInQuery = new URLSearchParams(window.location.search).get('rbs');
        if (isRBSInQuery) {
            yield call(localStorageSetItem, RBS_IN_QUERY, isRBSInQuery);
        }
        if (areValidValues(from, to)) {
            payload.from = from;
            payload.to = to;
        }
        const response = (yield call(postData, {
            queryString: driveQueries.SCH_GET_DRIVE_SLOTS,
            payload,
            spreadPayload: true,
        }));
        const schSlotData = response?.data?.sch_get_drive_slots;
        if (schSlotData) {
            yield put(driveOccurrenceDetailsSuccess(schSlotData));
            yield call(handleSagaSuccess, {
                callback: callback?.onSuccess,
                response: schSlotData,
            });
        }
        else {
            throw catchException.validationError('No source drive details found for the provided schedule ID.', {
                driveOccGuestId,
            });
        }
    }
    catch (error) {
        yield call(handleSagaError, {
            callback: callback?.onError,
            error: processSagaError(error, 'An error occurred in fetchDriveOccurrenceDetailsSagaWorker'),
            title: 'Fetch Drive Details Error',
            skipToast: true,
        });
    }
}
export function* cancelDriveOccurrenceGuestSagaWorker(action) {
    const { driveOccurrenceGuestId, callback } = action.payload;
    try {
        const response = (yield call(postData, {
            queryString: driveQueries.CANCEL_DRIVE_OCCURRENCE_GUEST,
            payload: { driveOccurrenceGuestId },
            spreadPayload: true,
        }));
        if (response) {
            const successPayload = response?.data?.canx_cancel_drive_occurrence_guest;
            yield put(cancelDriveOccurrenceGuestSuccess(successPayload));
            yield call(handleSagaSuccess, { callback: callback?.onSuccess, response: successPayload });
        }
        else {
            throw catchException.validationError('error while cancel the drive with ID', {
                driveOccurrenceGuestId,
            });
        }
    }
    catch (error) {
        yield call(handleSagaError, {
            callback: callback?.onError,
            error: processSagaError(error, `Failed to cancel drive occurrence with ID ${driveOccurrenceGuestId}`),
            title: 'Cancel Drive Details',
            skipToast: true,
        });
    }
}
export function* updateDriveMemberStatusSagaWorker(action) {
    const { updateLobbyDriveMemberStatusPayload, callback } = action.payload;
    const { drive_schedule_id, drive_occurrence_id, status } = updateLobbyDriveMemberStatusPayload;
    try {
        const response = (yield call(postData, {
            queryString: driveQueries.UPDATE_LOBBY_DRIVE_MEMBER_STATUS,
            payload: { drive_schedule_id, drive_occurrence_id, status },
            spreadPayload: true,
        }));
        if (response) {
            // const extractRes = response?.data?.sch_update_drive_member_status?.[0];
            yield put(updateDriveMemberStatusSuccess(response));
            yield call(handleSagaSuccess, { callback: callback?.onSuccess, response });
        }
        else {
            throw catchException.validationError('Update lobby drive details found for the provided IDs/status.', {
                drive_schedule_id,
                drive_occurrence_id,
                status,
            });
        }
    }
    catch (error) {
        yield call(handleSagaError, {
            callback: callback?.onError,
            error: processSagaError(error, 'An error occurred in updateDriveMemberStatusSagaWorker'),
            title: 'Update Lobby Drive Member Status Error',
            skipToast: true,
        });
    }
}
export function* fetchHeartBeatLobbySagaWorker(action) {
    const { driveOccurrenceId, callback } = action.payload;
    try {
        yield call(createHeartbeatWorker, {
            payload: { drive_occurrence_id: driveOccurrenceId },
            queryString: driveQueries.GET_HEART_BEAT,
            interval: 30 * 1000,
            callback,
        });
    }
    catch (error) {
        yield call(handleSagaError, {
            callback: callback?.onError,
            error: processSagaError(error, 'An error occurred in fetchHeartBeatLobbySagaWorker'),
            title: 'fetch heart beat drive details',
            skipToast: true,
        });
    }
}
export function* schDriveLobbyMeetingSubscriptionSagaWorker(action) {
    const { driveOccGuestId, callback } = action.payload;
    const query = driveQueries.SUBSCRIBE_DRIVE_LOBBY_MEETING;
    const variables = { drive_schedule_id: driveOccGuestId };
    // Create the Apollo subscription channel to receive data and errors
    const channel = createApolloSubscriptionChannel({
        query,
        variables,
    });
    try {
        // Start an infinite loop to continuously listen for data from the subscription
        while (true) {
            // Use `take` to wait for the next event (either data or error) from the Apollo subscription channel.
            const { data, error } = (yield take(channel)); // Type assertion to inform TypeScript about the expected data structure
            if (error) {
                yield call(handleSagaError, {
                    callback: callback?.onError,
                    error: processSagaError(error, 'An error occurred in subscription channel'),
                    title: 'Subscribe to looby channel',
                    skipToast: true,
                });
                break;
            }
            if (isSchDriveLobbyMeeting(data)) {
                let extractData = data?.sch_drive_lobby?.[0];
                if (!extractData.meeting_id || !extractData.meeting) {
                    // todo: remove createMockMeetingData mock data and take data subscription api
                    extractData = createMockMeetingData();
                }
                // const extractData = data?.sch_drive_lobby?.[0];
                yield put(subscribeLobbyDriveScheduleDetailsSuccess(extractData));
                yield call(handleSagaSuccess, { callback: callback?.onSuccess, response: extractData });
            }
        }
    }
    catch (error) {
        yield call(handleSagaError, {
            callback: callback?.onError,
            error: processSagaError(error, 'An error occurred in schDriveLobbyMeetingSubscriptionSagaWorker'),
            title: 'Subscribe to Drive Lobby Meeting',
            skipToast: true,
        });
    }
    finally {
        // Ensure that the subscription channel is closed after the saga completes
        channel.close();
    }
}
export function* schDriveLobbyMeetingSubscriptionSagaWatcher() {
    yield takeLatest(subscribeLobbyDriveScheduleRequest.type, schDriveLobbyMeetingSubscriptionSagaWorker);
}
export function* fetchDriveOccurrenceSagaWatcher() {
    yield takeLatest(fetchDriveDetails.type, fetchDriveOccurrenceGuestWorker);
}
export function* confirmDriveSlotSagaWatcher() {
    yield debounce(500, confirmDriveSlotRequest.type, confirmDriveSlotWorker);
}
export function* fetchDriveOccurrenceDetailsSagaWatcher() {
    yield debounce(500, fetchDriveOccurrenceDetails.type, fetchDriveOccurrenceDetailsSagaWorker);
}
export function* cancelDriveOccurrenceGuestSagaWatcher() {
    yield takeLatest(cancelDriveOccurrenceGuestRequest.type, cancelDriveOccurrenceGuestSagaWorker);
}
export function* updateDriveMemberStatusSagaWatcher() {
    yield takeLatest(updateDriveMemberStatusRequest.type, updateDriveMemberStatusSagaWorker);
}
export function* fetchHeartBeatLobbySagaWatcher() {
    yield takeLatest(heartBeatStatusRequest.type, fetchHeartBeatLobbySagaWorker);
}
export function* driveRootSaga() {
    yield fork(fetchDriveOccurrenceSagaWatcher);
    yield fork(confirmDriveSlotSagaWatcher);
    yield fork(fetchDriveOccurrenceDetailsSagaWatcher);
    yield fork(cancelDriveOccurrenceGuestSagaWatcher);
    yield fork(schDriveLobbyMeetingSubscriptionSagaWatcher);
    yield fork(updateDriveMemberStatusSagaWatcher);
    yield fork(fetchHeartBeatLobbySagaWatcher);
}
