import { all, call, fork, put, retry, select, takeEvery } from '@redux-saga/core/effects';
import { saveAnswer, storeFailedToSavedChunk, storeSavedChunk, uploadChunk, uploadComplete, } from '@containers/Assessment/AsyncPlayGround/slice';
import { selectUser } from '@containers/Auth/selectors';
import postData from '@utils/postData';
import { UPLOAD_FILE } from '@containers/App/queries';
import { catchError } from '@utils/sentry';
import { selectFailedToSaveChunks, selectIsRecording, 
// selectIsSubmitted,
// selectIsUploadingCompleted,
selectRecordedChunks, selectSavedChunks, } from '@containers/Assessment/AsyncPlayGround/selectors';
import { axiosPostData } from '@utils/axiosApi';
import { difference, last, sortBy } from 'lodash';
import { selectCurrentQuestion, selectStartSectionResponse } from '@containers/Assessment/selectors';
import { SAVE_ANSWERS } from '@containers/Assessment/queries';
import { getCurrentDate } from '@utils/dateHelpers';
import { fetchNextQuestionById, saveAppState, storeAnswer } from '@containers/Assessment/slice';
import { nextAsyncQuestionClick } from '@utils/mixpanel/mixpanelActions';
export function* uploadChunkWorker() {
    const chunks = (yield select(selectRecordedChunks));
    const chunk = (yield call(last, chunks));
    const user = (yield select(selectUser));
    const extension = chunk.mimeType && chunk.mimeType.split(';')[0].split('/')[1];
    const formattedChunkPayload = {
        file_type_id: 9,
        file: chunk.data,
        timestamp: chunk.timestamp,
        fileSize: chunk.data.size,
        extension,
        original_name: `video_${chunk.sequence}.${extension}`,
        owner_id: user?.user_id,
    };
    try {
        const response = (yield retry(3, 20, postData, {
            queryString: UPLOAD_FILE,
            payload: formattedChunkPayload,
            spreadPayload: true,
        }));
        const { data: _file, ...rest } = chunk;
        if (response && response?.data?.file_upload_file) {
            const fileUploadData = response?.data?.file_upload_file?.data;
            const URL = response?.data?.file_upload_file?.data?.url;
            const { url: _url, ...newFileUploadData } = fileUploadData;
            const formData = new FormData();
            Object.keys(newFileUploadData).forEach((key) => {
                formData.append(key, newFileUploadData[key]);
            });
            formData.append('file', formattedChunkPayload?.file);
            yield call(axiosPostData, URL, formData);
            yield put(storeSavedChunk({ chunk: { ...rest, file_id: response.data?.file_upload_file?.id } }));
        }
        else {
            throw Error('No Response from file upload api');
        }
    }
    catch (error) {
        const { file: _file, ...rest } = formattedChunkPayload;
        yield put(storeFailedToSavedChunk({ chunk }));
        yield call(catchError, {
            title: 'Async file update failed',
            extraScope: { key: 'chunk', value: JSON.stringify(rest) },
            error: error,
            skipToast: true,
        });
    }
}
export function* uploadChunkObserverWorker() {
    try {
        const isRecording = (yield select(selectIsRecording));
        if (!isRecording) {
            const savedChunkRecords = (yield select(selectSavedChunks));
            const recordedChucks = (yield select(selectRecordedChunks));
            const failedToSaveChunks = (yield select(selectFailedToSaveChunks));
            const allRecordedSequence = sortBy(recordedChucks, 'sequence').map((record) => record.sequence);
            const allSavedSequence = sortBy(savedChunkRecords, 'sequence').map((record) => record.sequence);
            const diff = difference(allRecordedSequence, allSavedSequence);
            if (diff.length === 0) {
                yield put(uploadComplete({ uploadPercentage: 100 }));
            }
            else if (diff.length && savedChunkRecords.length + failedToSaveChunks.length === recordedChucks.length) {
                yield put(uploadComplete({ uploadPercentage: Math.ceil((savedChunkRecords.length / recordedChucks.length) * 100) }));
            }
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'Async post chunk upload failed',
            error: error,
            skipToast: true,
        });
    }
}
export function* saveAnswerWorker({ payload, }) {
    try {
        // const isUploadingCompleted = (yield select(selectIsUploadingCompleted)) as boolean;
        // const isSubmitted = (yield select(selectIsSubmitted)) as boolean;
        // if (isUploadingCompleted && isSubmitted) {
        const currentQuestion = (yield select(selectCurrentQuestion));
        const startSectionResponse = (yield select(selectStartSectionResponse));
        const savedChunks = (yield select(selectSavedChunks));
        const formattedChunks = savedChunks.map((chunk) => ({
            question_id: currentQuestion.id,
            section_id: startSectionResponse.id,
            answered_at: getCurrentDate()?.toUTC()?.toISO(),
            file_id: chunk.file_id,
            sequence: chunk.sequence,
        }));
        const response = (yield retry(3, 20, postData, {
            queryString: SAVE_ANSWERS,
            payload: formattedChunks,
        }));
        // const response = (yield call(postData, {
        //   queryString: SAVE_ANSWERS,
        //   payload: formattedChunks,
        // })) as SaveAnswersOutput;
        const result = response?.data?.ae_save_answer[0];
        if (result?.success) {
            nextAsyncQuestionClick();
            yield put(storeAnswer(result.data));
            yield put(saveAppState({ data: { remaining_time: 0 } }));
            yield put(fetchNextQuestionById({ callback: payload.callback }));
        }
        else {
            const err = Array.isArray(result?.error_message) ? result?.error_message?.[0] : result?.error_message;
            throw Error(`Failed to save answer:  ${err}`);
        }
        // }
    }
    catch (error) {
        yield call(catchError, {
            title: 'saveAnswerWorker',
            error: error,
        });
        if (payload?.callback?.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* saveFileRecordWatcher() {
    yield takeEvery([storeSavedChunk.type, storeFailedToSavedChunk.type], uploadChunkObserverWorker);
}
export function* uploadChunkWatcher() {
    yield takeEvery(uploadChunk.type, uploadChunkWorker);
}
export function* saveAnswerWatcher() {
    yield takeEvery(saveAnswer.type, saveAnswerWorker);
}
export function* asyncAnswerRootSaga() {
    yield all([fork(uploadChunkWatcher), fork(saveFileRecordWatcher), fork(saveAnswerWatcher)]);
}
