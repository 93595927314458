import { call, fork, takeLatest } from 'redux-saga/effects';
import { catchError } from '@utils/sentry';
import postData from '@utils/postData';
import { interviewDecline } from './slice';
import { DECLINE_INTERVIEW } from './queries';
export function* interviewDeclineWorker({ payload }) {
    try {
        const response = (yield call(postData, {
            queryString: DECLINE_INTERVIEW,
            payload: payload?.data,
        }));
        const successCount = response?.data?.sch_rsvp_action?.success_count ?? 0;
        if (payload.callback?.onSuccess && successCount > 0) {
            yield call(payload.callback.onSuccess, response);
        }
        else {
            throw new Error('An error occurred while declining the interview');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Decline Interview', error: error, skipToast: true });
        if (payload.callback?.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* interviewDeclineWatcher() {
    yield takeLatest(interviewDecline.type, interviewDeclineWorker);
}
export function* interviewDeclineSaga() {
    yield fork(interviewDeclineWatcher);
}
