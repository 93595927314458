import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import messages from './messages';
const GrowthBookError = (props) => {
    const { errorMessage } = props;
    const handleRefresh = () => {
        window.location.reload();
    };
    return (_jsx("div", { className: "mx-4 flex h-screen items-center justify-center", children: _jsx("div", { className: "align-center relative flex flex-1 items-center justify-center space-y-6", children: _jsxs("div", { className: "flex flex-col items-center justify-center md:w-[431px]", children: [_jsx("div", { className: "mt-4 text-lg font-semibold text-gray-700", children: _jsx(FormattedMessage, { ...messages.loadingError }) }), _jsx("div", { className: "w-fit items-center justify-center text-base font-normal text-gray-700", children: errorMessage }), _jsx("div", { className: "w-fit items-center justify-center text-center text-base font-normal text-gray-700", children: _jsx(FormattedMessage, { ...messages.pageBodyMessage }) }), _jsx("button", { className: "mt-2 rounded-sm bg-blue-700 px-3 py-1.5 text-center text-base font-medium text-white shadow-md", onClick: handleRefresh, children: _jsx(FormattedMessage, { ...messages.refresh }) })] }) }) }));
};
export default GrowthBookError;
