import { call, fork, takeLatest } from 'redux-saga/effects';
import { catchError } from '@utils/sentry';
import postData from '@utils/postData';
import { proposeNewTime } from './slice';
import { PROPOSE_NEW_TIME } from './queries';
export function* proposeNewTimeWorker({ payload }) {
    try {
        yield call(postData, {
            queryString: PROPOSE_NEW_TIME,
            payload: payload?.data,
            spreadPayload: true,
        });
        if (payload.callback?.onSuccess) {
            yield call(payload.callback.onSuccess);
        }
        else {
            throw new Error('An error occurred while proposing the new time');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Propose Time', error: error });
        if (payload.callback?.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* proposeNewTimeWatcher() {
    yield takeLatest(proposeNewTime.type, proposeNewTimeWorker);
}
export function* proposedTimeSaga() {
    yield fork(proposeNewTimeWatcher);
}
// export function* fetchBusySlotsWorker({ date }: any): Generator {
//   try {
//     const user = (yield select(selectUser)) as User;
//     const payload = {
//       user_id: user?.user_id,
//       from: parseDateTime(date)?.startOf('day').toUTC().toISO(),
//       to: parseDateTime(date)?.endOf('day').toUTC().toISO(),
//       status: [
//         meetingStatus?.COMPLETED,
//         meetingStatus?.DRAFT,
//         meetingStatus?.SCHEDULED,
//         meetingStatus?.IN_PROGRESS,
//         meetingStatus?.NO_SHOW,
//       ],
//     };
//     const response = yield fetchData({
//       queryString: GET_AUTH_USERS_BUSY,
//       queryVariables: {
//         payload,
//       },
//       queryKey: 'wft_free_busy',
//       forceRefresh: true,
//     });
//     const record = Array.isArray(response) && response ? response[0] : {};
//     if (record && record?.success && record?.data) {
//       //   yield put (storeBusySlots{
//       //     slots: record?.data,
//       //     date: parseDateTime(date)?.startOf('day').toUTC().toISO(),
//       //   });
//     } else {
//       throw Error(record?.error_message || 'No data found');
//     }
//   } catch (error) {
//     yield call(catchError, { title: 'Busy Slot error', error: error as Error });
//     //@ts-expect-error aaaa
//     if (payload?.callback?.onError) {
//       //@ts-expect-error aaaa
//       yield call(payload?.callback.onError, error as Error);
//     }
//   }
// }
// export function* fetchBusySlotsWatcher() {
//   yield takeLatest(fetchBusySlots.type, fetchBusySlotsWorker);
// }
