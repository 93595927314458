import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    request: {},
    data: {
        meeting: {},
        participant: {},
    },
    meetingState: {
        isMeetingCompleted: false,
        isMeetingCancelled: false,
        isCurrentUserParticipant: false,
        isMeetingStarted: false,
        isMeetingDeclined: false,
        meetingConditionType: null,
    },
};
export const schedulingSlice = createSlice({
    name: 'scheduling',
    initialState,
    reducers: {
        fetchMeeting(_state, _action) {
            // state.request = action.payload;
        },
        storeMeeting(state, action) {
            const { meeting, participant, isMeetingStarted, isMeetingCancelled, isMeetingCompleted, isMeetingDeclined, meetingConditionType, } = action.payload;
            state.data = {
                meeting,
                participant,
            };
            state.meetingState = {
                isMeetingStarted,
                isMeetingCancelled,
                isMeetingCompleted,
                isMeetingDeclined,
                isCurrentUserParticipant: !!participant,
                meetingConditionType,
            };
        },
    },
});
export const { fetchMeeting, storeMeeting } = schedulingSlice.actions;
export default schedulingSlice.reducer;
