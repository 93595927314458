import { gql } from '@apollo/client';
export const GET_STEP_DETAILS = gql(`
  query canx_get_step_details($step_id: Int!) {
    canx_get_step_details(step_id: $step_id) {
      id
      name
      is_proctoring
      proview_type
      step_forms {
        id
        step_form_type
        form {
          id
          type
        }
      }
    }
  }
`);
export const START_ASSESSMENT = gql(`
  mutation startAssessment($payload: [canx_start_assessment_input]!) {
    canx_start_assessment(data: $payload) {
      data {
        id
        candidate_id
        catalog_assessment_id
        external_id
        instance_status
        old_id
        workflow_candidate_step_id
        catalog_assessment {
          assessment_id
          catalog_id
          id
          assessment {
            description
            id
            level
            name
            status
            assessment_sections {
              allow_phone_interview
              allow_reattempt
              allow_review
              allow_skipping
              answer_time
              assessment_id
              external_id
              id
              is_shuffle_option
              is_shuffle_question
              name
              negative_mark
              positive_mark
              preparation_time
              section_type
              show_testcases
              sort_order
            }
          }
        }
      }
      success
      error_message
    }
  }
`);
export const START_SECTION = gql(`
  mutation startSection($payload: [canx_start_next_section_input]!) {
    canx_start_next_section(data: $payload) {
      error_message
      success
      data {
        assessment_section_id
        completed_at
        external_id
        id
        instance_id
        invited_at
        is_completed
        last_active_at
        launch_code
        launch_url
        launch_username
        questions
        started_at
        assessment_section {
          allow_phone_interview
          allow_reattempt
          allow_review
          allow_skipping
          answer_time
          assessment_id
          external_id
          id
          is_shuffle_option
          is_shuffle_question
          name
          negative_mark
          positive_mark
          preparation_time
          section_type
          show_testcases
          sort_order
          max_section_time
        }
      }
    }
  }
`);
export const GET_NEXT_QUESTION_BY_ID = gql(`
  mutation getNextQuestionById($section_id: Int!) {
    ae_get_next_question(section_id: $section_id) {
      error_message
      success
      data {
        answer_time
        content
        id
        is_shuffle
        level
        preparation_time
        question_bank_id
        is_correct
        question_allowed_language {
          value
        }
        question_choices {
          choice_order
          id
          value
        }
        question_languages {
          id
          language {
            value
          }
        }
        question_type {
          name
        }
      }
    }
  }
`);
export const GET_ALL_QUESTION = gql(`
  mutation getAllQuestion($section_id: Int!, $instance_id: Int!) {
    ae_get_question_list(instance_id: $instance_id, section_id: $section_id) {
      data {
        answer_time
        content
        is_shuffle
        id
        level
        preparation_time
        question_allowed_language {
          value
        }
        question_bank_id
        question_choices {
          choice_order
          id
          value
          is_correct
        }
        question_languages {
          id
          language {
            value
          }
        }
        question_type {
          name
        }
      }
      error_message
      success
    }
  }
`);
// content;
// type;
// props;
export const SAVE_ANSWERS = gql(`
  mutation saveAnswers($payload: [ae_save_answer!]) {
    ae_save_answer(data: $payload) {
      error_message
      success
      data {
        answered_at
        choice
        code_response
        id
        is_correct_choice
        question_id
        section_id
        text_response{
          doc {
            id
          }
        }
        time_to_response
      }
  }
  }
`);
export const STOP_SECTION = gql(`
  mutation stopSection($payload: [ae_stop_section_input]!) {
    ae_stop_section(data: $payload) {
      data {
        assessment_section_id
        completed_at
        external_id
        id
        instance_id
        invited_at
        is_completed
        last_active_at
        launch_code
        launch_url
        launch_username
        questions
        started_at
      }
      success
      error_message
    }
  }
`);
export const CREATE_TEST_INSTANCE = gql(`
  mutation createTestInstance($test_instance_id: Int!, $reattempt: Boolean! ){
    canx_create_test_instance(data: {test_instance_id: $test_instance_id, reattempt: $reattempt}){
      data {
        test_instance_id
        workflow_step_id
      }
      success
      error_message
    }
  }
`);
export const GET_TCF_MEASUREMENT = gql(`
query tsfMeasurement($test_instance_id:Int!) {
  ae_get_instance_score(data: {test_instance_id: $test_instance_id}) {
    data {
      id
      instance_id
      section_id
      section_configuration {
        id
        type
        assessment_section_id
      }
      measurement_attributes {
        id
        numerical_score
        ordinal {
          id
          label
        }
        section_configuration_attribute {
          id
          type
          attribute {
            attribute_type
            max_value
            min_value
            skill_id
            skill {
              name
            }
          }
        }
      }
    }
  }
}
`);
export const CANX_GET_APP_STATE = gql(`
  query getAppState($section_id: [Int]!) {
    canx_get_app_state(section_ids: $section_id){
      section_id
      state
    }
  }
`);
export const CANX_UPSERT_APP_STATE = gql(`
  mutation saveAppState($payload: [canx_upsert_app_state_input]!){
    canx_upsert_app_state(data: $payload) {
      data {
        section_id
        state
      }
      error_message
      success
    }
  }
`);
