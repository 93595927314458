import { gql } from '@apollo/client';
export const GET_ORDER_DETAILS_QUERY = gql `
  query canxGetOrderDetails($order_id: Int!) {
    canx_get_order_details(order_id: $order_id) {
      id
      status
      order_items {
        item_id
        order_id
        unit_price
        total_price
        quantity
        item {
          id
          status
          eligible_refund_rule {
            refund_percent
            timeframe
          }
          drive_occurrence_guest {
            id
            instruction
            drive_schedule {
              id
              booking_end_date
              booking_start_date
              drive_type
              end_date
              name
              start_date
              status
              timezone
              workflow_step_id
              location
              slot_capacity
              slot_duration
            }
            drive_occurrence {
              drive_schedule_id
              end_date
              start_date
              status
              workflow_step_id
            }
          }
          sku {
            name
            refund_rules {
              refund_percent
              timeframe
            }
            reschedule_rules {
              fee_percent
              timeframe
            }
            currency_code
            plan_type
          }
          eligible_reschedule_rule {
            fee_percent
            timeframe
          }
        }
      }
      transactions {
        payment_id
        external_id
        status
        total_amount
        type
        invoices {
          id
          invoice_number
        }
      }
    }
  }
`;
export const CREATE_TRANSACTION_MUTATION = gql `
  mutation createTransaction($orderId: Int!, $coupon_code: String) {
    pay_create_transaction(order_id: $orderId, coupon_code: $coupon_code) {
      order_id
      payment_id
      transaction_external_id
      payment_gateway_type
      payment_url
    }
  }
`;
export const VALIDATE_COUPON_MUTATION = gql `
  mutation validateCoupon($orderId: Int!, $couponCode: String!) {
    pay_validate_coupon(order_id: $orderId, coupon_code: $couponCode) {
      order_id
      coupon_code
      order_items {
        id
        order_item_id
        item_id
        unit_price
        order_id
        coupon_id
        quantity
        total_price
      }
    }
  }
`;
export const PAYMENT_RESCHEDULED_SLOT = gql `
  mutation paymentRescheduledSlot($driveOccGuestId: Int!) {
    canx_reschedule_drive_guest(drive_occurrence_guest_id: $driveOccGuestId) {
      drive_occurrence_guest_id
      pay_item {
        id
        order_items {
          item_id
          order_id
          order {
            status
            transactions {
              total_amount
              external_id
              payment_id
              status
              type
            }
            id
          }
        }
      }
    }
  }
`;
export const GET_PAYMENT_INVOICE = gql `
  query getInvoice(
    $invoice_id_check: Int_comparison_exp = { _is_null: true }
    $invoice_number_check: String_comparison_exp = { _is_null: true }
  ) {
    pay_invoice(where: { id: $invoice_id_check, invoice_number: $invoice_number_check }) {
      id
      invoice_number
      payment_method
      tax
      type
      user_id
      transaction {
        payment_id
        status
        total_amount
        order {
          id
          order_items {
            coupon_id
            quantity
            total_price
            unit_price
            order_id
          }
          ordered_by_details {
            email
            id
            name
          }
        }
        transaction_status_histories(where: { status_to: { _eq: COMPLETED } }, order_by: { id: desc }) {
          created_at
        }
      }
    }
  }
`;
export default {
    GET_ORDER_DETAILS_QUERY,
    CREATE_TRANSACTION_MUTATION,
    VALIDATE_COUPON_MUTATION,
    PAYMENT_RESCHEDULED_SLOT,
    GET_PAYMENT_INVOICE,
};
