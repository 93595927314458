import { call, fork, takeLatest } from 'redux-saga/effects';
import { catchError } from '@utils/sentry';
import postData from '@utils/postData';
import { DECLINE_PROPSED_TIME } from './queries';
import { declineNewTime } from './slice';
export function* declineProposedTime({ payload }) {
    try {
        const queryVariables = payload?.data;
        yield call(postData, {
            queryString: DECLINE_PROPSED_TIME,
            payload: queryVariables,
            spreadPayload: true,
        });
        if (payload.callback?.onSuccess) {
            yield call(payload.callback.onSuccess);
        }
        else {
            throw new Error('An error occurred while declining the proposed time');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Decline Time', error: error });
        if (payload.callback?.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* declineProposedTimeWatcher() {
    yield takeLatest(declineNewTime.type, declineProposedTime);
}
export function* declineProposedTimeSaga() {
    yield fork(declineProposedTimeWatcher);
}
