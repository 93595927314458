import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    feedbacks: [],
    isLoading: false,
    error: null,
};
export const feedbackSlice = createSlice({
    name: 'feedback',
    initialState,
    reducers: {
        initializeFeedback: (state) => ({
            ...state,
            ...initialState,
            isLoading: true,
        }),
        createFeedback: (_state, _action) => {
            // state.isLoading = true;
        },
        storeFeedbackResponse: (state, action) => {
            state.feedbacks = action.payload.data.canx_create_feedback;
            state.isLoading = false;
            state.error = null;
        },
    },
});
export const { initializeFeedback, createFeedback, storeFeedbackResponse } = feedbackSlice.actions;
export default feedbackSlice.reducer;
