import { jsx as _jsx } from "react/jsx-runtime";
import { useRouteError } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
const RootErrorPage = () => {
    const error = useRouteError();
    // console.error(error);
    return (_jsx("div", { id: "root-error-page", children: _jsx("p", { children: _jsx("i", { children: error?.statusText || error?.message || _jsx(FormattedMessage, { ...messages.something_went_wrong }) }) }) }));
};
export default RootErrorPage;
