import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentCheck, faCommentExclamation, faCommentLines, faCommentXmark } from '@fortawesome/pro-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { get } from 'lodash';
import { handleEnterKeyPress } from '@utils/keyboardHelpers';
const dataMap = {
    success: { Icon: faCommentCheck, color: 'text-green-700', borderColor: 'border-green-100' },
    danger: { Icon: faCommentXmark, color: 'text-rose-600', borderColor: 'border-rose-100' },
    warning: { Icon: faCommentExclamation, color: 'text-yellow-500', borderColor: 'border-yellow-100' },
    info: { Icon: faCommentLines, color: 'text-blue-700', borderColor: 'border-blue-100' },
    default: { Icon: faCommentLines, color: 'text-blue-700', borderColor: 'border-blue-100' },
    processing: { Icon: faCommentLines, color: 'text-blue-700', borderColor: 'border-blue-100' },
};
const Content = (props) => {
    const { title, summary, close, actions, variant } = props;
    return (_jsxs("div", { className: classNames('flex w-[350px] flex-1 items-start border-l-8 bg-white py-3', get(dataMap, `${variant}.borderColor`)), children: [_jsx("div", { className: "mt-1 flex w-11 items-start justify-center", "data-chromatic": "ignore", children: _jsx(FontAwesomeIcon, { icon: get(dataMap, `${variant}.Icon`), className: classNames(get(dataMap, `${variant}.color`)), "aria-hidden": "true", size: "lg" }) }), _jsxs("div", { className: classNames('flex flex-1 flex-col items-center justify-center'), children: [title ? (_jsx(_Fragment, { children: _jsx("div", { className: "flex w-full justify-between", children: _jsxs("div", { children: [_jsx("span", { className: "self-stretch text-sm font-semibold leading-5 text-gray-900", children: title }), summary ? _jsx("p", { className: "text-sm font-normal text-gray-900", children: summary }) : _jsx(_Fragment, {})] }) }) })) : (_jsx(_Fragment, { children: _jsx("div", { className: "flex h-full w-full items-center justify-between", children: _jsx("div", { children: summary ? _jsx("p", { className: "pt-1 text-sm font-normal text-gray-900", children: summary }) : _jsx(_Fragment, {}) }) }) })), _jsx("div", { className: "flex w-full items-center justify-start space-x-4", children: actions ? (_jsx(_Fragment, { children: actions.map((action) => (_jsx("span", { role: "button", tabIndex: -1, className: classNames('cursor-pointer text-sm font-medium', get(dataMap, `${action.variant || 'default'}.color`)), onClick: action.onClick, onKeyDown: (e) => handleEnterKeyPress(e, action.onClick), children: action.label }, action.label))) })) : (_jsx(_Fragment, {})) })] }), _jsx("div", { className: "pl-4 pr-4 pt-[2px]", children: _jsx(FontAwesomeIcon, { icon: faXmark, size: "lg", onClick: close, className: "text-black", "data-testid": "close-toast-notification" }) })] }));
};
export default Content;
