import { gql } from '@apollo/client';
export const UPLOAD_MEDIA_FILE = gql(`
  mutation fileUpload(
    $fileName: String!
    $fileType: String!
    $user_id: Int!
    $file_type_id: Int!
  ) {
    file_upload_file(
      extension: $fileType
      file_type_id: $file_type_id
      original_name: $fileName
      owner_id: $user_id
    ) {
      id
      presigned_url
      mime
    }
  }
`);
export const DOWNLOAD_MEDIA_FILE = gql(`
  query fileDownload($file_id: Int!) {
    file_download_file(id: $file_id) {
      resource_url
      id
    }
  }
`);
export default { UPLOAD_MEDIA_FILE, DOWNLOAD_MEDIA_FILE };
