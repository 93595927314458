import { jsx as _jsx } from "react/jsx-runtime";
import { RouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import router from './router';
function FallbackComponent() {
    return (_jsx("div", { className: "flex h-full w-full flex-1 items-center justify-center border border-blue-600", "data-testid": "fallback-component", children: _jsx(FontAwesomeIcon, { icon: faSpinner, spin: true }) }));
}
const App = () => (_jsx(Sentry.ErrorBoundary, { fallback: _jsx(FallbackComponent, {}), showDialog: true, children: _jsx(RouterProvider, { router: router }) }));
export default App;
