import { call, fork, takeLatest } from 'redux-saga/effects';
import { catchError } from '@utils/sentry';
import postData from '@utils/postData';
import { acceptNewTime } from './slice';
import { CHANGE_INTERVIEWER } from './queries';
export function* acceptTimebyInterviewer({ payload }) {
    try {
        const queryVariables = payload?.data;
        yield call(postData, {
            queryString: CHANGE_INTERVIEWER,
            payload: queryVariables,
            spreadPayload: true,
        });
        if (payload.callback?.onSuccess) {
            yield call(payload.callback.onSuccess);
        }
        else {
            throw new Error('An error occurred while accepting the proposed time');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Accept Time', error: error });
        if (payload.callback?.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* acceptTimebyInterviewerWatcher() {
    yield takeLatest(acceptNewTime.type, acceptTimebyInterviewer);
}
export function* acceptProposedTimeSaga() {
    yield fork(acceptTimebyInterviewerWatcher);
}
