import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    profile: null,
    candidateId: null,
};
export const candidateProfileSlice = createSlice({
    name: 'candidateProfile',
    initialState,
    reducers: {
        initialize: (_state, _action) => {
            // state.isLoading = true;
        },
        createProfileRequest: (_state, _action) => {
            // state.isLoading = true;
        },
        createProfileSuccess: (state, action) => {
            state.profile = action.payload.data;
            state.candidateId = action.payload.data.candidate_id;
        },
        clearCandidateProfile: (state) => {
            state.profile = null;
            state.candidateId = null;
        },
        createFaceAuthRequest: (_state, _action) => {
            // state.isLoading = true;
        },
        createFaceAuthSuccess: (_state, _action) => {
            // state.isLoading = false;
        },
    },
});
export const { createProfileRequest, createProfileSuccess, clearCandidateProfile, createFaceAuthRequest, createFaceAuthSuccess, initialize, } = candidateProfileSlice.actions;
export default candidateProfileSlice.reducer;
