import { every, filter, find, findIndex, first, flatMap, get, map, sortBy } from 'lodash';
import { convertTimeToSeconds } from '@utils/helpers';
import { getCurrentDate } from '@utils/dateHelpers';
export const processStartAssessmentResponse = (data) => {
    const { catalog_assessment, ...startAssessmentResponse } = data;
    const assessment = catalog_assessment?.assessment;
    // const assessmentSections = assessment?.assessment_sections;
    const assessmentSections = assessment?.assessment_sections || [];
    const sortedAssessmentSections = sortBy(assessmentSections, 'sort_order');
    const firstSection = first(sortedAssessmentSections);
    return {
        assessment,
        assessmentSections,
        assessmentSection: firstSection,
        startAssessmentResponse,
    };
};
export const processStartSectionResponse = (data, appState) => {
    const { assessment_section, questions, ...startSectionResponse } = data;
    let nextQuestionId;
    const appStateData = Number(appState?.section_id) === Number(startSectionResponse.id) ? appState?.state[0] : {};
    if (!appStateData?.nextQuestionId && !appStateData?.currentQuestionId) {
        nextQuestionId = first(questions);
    }
    else if (appStateData?.currentQuestionId) {
        const currentQuestionIdAnswered = find(appStateData?.answers, { question_id: appStateData?.currentQuestionId });
        nextQuestionId = currentQuestionIdAnswered ? appStateData?.nextQuestionId : appStateData?.currentQuestionId;
    }
    return {
        assessmentSection: assessment_section,
        next_question_id: nextQuestionId,
        startSectionResponse,
        questionsIds: questions,
        parsedMaxSectionTime: appStateData?.remaining_time ?? convertTimeToSeconds(assessment_section?.max_section_time),
        answers: appStateData?.answers || [],
    };
};
export const getNextQuestionId = (questionsIds, currentQuestionId) => {
    const currentQuestionIndex = findIndex(questionsIds || [], (question) => question === currentQuestionId);
    // return questionsIds?.length ? questionsIds[currentQuestionIndex + 1] : null;
    if (currentQuestionIndex >= 0 && currentQuestionIndex < questionsIds.length - 1) {
        return questionsIds[currentQuestionIndex + 1]; // Return next question id
    }
    return null;
};
export const getOverallSections = (data) => filter(data, (item) => item?.section_configuration?.type === 'OVERALL');
export const getOverallNumericScore = (data) => {
    if (!data)
        return null;
    const overallSections = getOverallSections(data);
    const overallAttributes = flatMap(overallSections, (section) => filter(section.measurement_attributes, (attr) => get(attr, 'section_configuration_attribute.type') === 'OVERALL' &&
        get(attr, 'section_configuration_attribute.attribute.attribute_type') === 'NUMERIC'));
    return overallAttributes.length > 0 ? overallAttributes[0] : null;
};
export const getSkillScores = (data) => {
    if (!data)
        return null;
    const overallSections = getOverallSections(data);
    const skillScores = flatMap(overallSections, (section) => filter(section.measurement_attributes, (attr) => get(attr, 'section_configuration_attribute.type') === 'SUB_VALUE' &&
        get(attr, 'section_configuration_attribute.attribute.attribute_type') === 'NUMERIC'));
    return skillScores.length > 0 ? skillScores : null;
};
export const getResult = (data) => {
    if (!data)
        return null;
    const overallSections = getOverallSections(data);
    const result = flatMap(overallSections, (section) => filter(section.measurement_attributes, (attr) => get(attr, 'section_configuration_attribute.type') === 'OVERALL' &&
        get(attr, 'section_configuration_attribute.attribute.attribute_type') === 'ORDINAL'));
    return result.length > 0 ? get(result[0], 'ordinal.label', null) : null;
};
export const parseSubmittedAnswer = (payload, currentQuestion, startSectionResponse) => {
    let result;
    const selectedChoices = payload?.data?.choice;
    const questionChoices = currentQuestion?.question_choices;
    const formattedChoices = map(selectedChoices, (choice) => {
        const questionChoice = find(questionChoices, { id: choice });
        return {
            id: choice,
            is_correct: questionChoice?.is_correct,
        };
    });
    if (questionChoices) {
        result = {
            data: {
                id: 'has_to_save',
                section_id: startSectionResponse.id,
                question_id: currentQuestion.id,
                choice: selectedChoices,
                // time_to_response?: string;
                answered_at: getCurrentDate()?.toUTC().toISO(),
                is_correct_choice: every(formattedChoices, (choice) => typeof choice.is_correct === 'boolean')
                    ? every(formattedChoices, ['is_correct', true])
                    : null,
            },
            success: true,
        };
    }
    else {
        result = {
            error_message: 'Error on save answer in state',
            data: null,
            success: false,
        };
    }
    return result;
};
export const parseBulkSavedAnswer = (response, allAnswers) => {
    const saveAnswerResponse = response.data.ae_save_answer;
    const errorResponse = filter(saveAnswerResponse, { success: false });
    const successResponse = filter(saveAnswerResponse, { success: true });
    const formattedAnswers = allAnswers.map((answer) => {
        const foundItem = successResponse.find((item) => item.data.question_id === answer.question_id);
        return foundItem ? foundItem.data : answer;
    });
    return {
        formattedAnswers: formattedAnswers || [],
        failedToSaveAnswer: errorResponse || [],
        successToSaveAnswer: successResponse || [],
    };
};
export const getCurrentQuestionIndex = (questionIds, currentQuestion) => {
    const index = findIndex(questionIds, (id) => id === currentQuestion?.id);
    return index + 1;
};
