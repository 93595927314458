import { processQuery } from '@utils/processQuery';
import client from '@utils/apollo';
export const makeRequest = async ({ payload, queryString, context = {}, spreadPayload }) => client.mutate({
    mutation: queryString,
    variables: spreadPayload ? { ...payload } : { payload },
    context: {
        ...context,
    },
});
const postData = async ({ queryString, payload, spreadPayload, context }) => {
    if (!queryString) {
        throw Error('queryString not provided');
    }
    const graphQuery = processQuery(queryString);
    return makeRequest({
        payload,
        queryString: graphQuery,
        spreadPayload,
        context,
    });
};
export default postData;
