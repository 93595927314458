import { initializeApp } from 'firebase/app';
import { getAuth, signOut } from 'firebase/auth';
import { catchError } from '@utils/sentry';
import { appName } from '@utils/localStorageHelpers';
export let firebaseInstance;
export const initializeFirebase = () => {
    try {
        const config = {
            apiKey: process.env.FIREBASE_API_KEY,
            authDomain: process.env.FIREBASE_API_DOMAIN,
        };
        const app = initializeApp(config, { name: appName });
        const auth = getAuth(app);
        firebaseInstance = {
            firebaseApp: app,
            fireBaseAuth: auth,
        };
        return firebaseInstance;
    }
    catch (error) {
        catchError({ title: 'Firebase initialization', error: error });
    }
};
export const getFirebaseIdToken = async () => {
    const auth = firebaseInstance?.fireBaseAuth;
    await auth.authStateReady();
    const currentUser = auth?.currentUser;
    let idToken = '';
    if (typeof currentUser?.getIdToken === 'function') {
        idToken = await currentUser?.getIdToken();
    }
    return idToken;
};
export const firebaseLogout = async () => {
    const auth = firebaseInstance?.fireBaseAuth;
    await signOut(auth);
};
export default initializeFirebase;
