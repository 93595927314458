import { call, debounce, fork, put, select, takeLatest } from '@redux-saga/core/effects';
import { first, get } from 'lodash';
import { authenticate, authenticateFailed, fetchCandidateUser, getEmailOtp, getEmailOtpSuccess, getPhoneFirebaseOtp, getPhoneFirebaseOtpSuccess, initialize, initializeComplete, initializeSocialLogin, storeCandidateUser, storeIdToken, storeQueryParams, storeStoredSession, unAuthenticate, verifyEmailOtp, verifyFirebaseOtp, } from '@containers/Auth/slice';
import { catchError } from '@utils/sentry';
import initializeFirebase, { firebaseLogout, getFirebaseIdToken } from '@utils/firebase';
import { QUERY_PARAMS_STORAGE_KEY, RESCHEDULED_ORDER_ID, clearLocalStorage, localStorageGetItem, localStorageSetItem, } from '@utils/localStorageHelpers';
import { fetchCountryCodeApi } from '@utils/fetchCountryCode';
import postData from '@utils/postData';
import fetchData from '@utils/fetchData';
import client from '@utils/apollo';
import { selectAuth, selectIdToken, selectQueryParams } from './selectors';
import { GET_CURRENT_CANDIDATE_USER, GET_CUSTOM_TOKEN, GET_CUSTOM_TOKEN_BY_ID_TOKEN, OTP_REQUEST, SIGNUP, VERIFY_OTP, } from './queries';
import { authenticateFirebase, getAllParams, getStoredQueryParams, initSocialLogin, isTokenExpired, parseToken, signInCredential, signInPhone, } from './helpers';
export function* initializeWorker() {
    try {
        yield call(initializeFirebase);
        let params = (yield call(getAllParams));
        const storedParams = (yield call(getStoredQueryParams));
        if (params.c || params.tid) {
            yield call(firebaseLogout);
            yield call(clearLocalStorage);
        }
        else {
            params = { ...storedParams, ...params };
        }
        const latestOrderId = yield call(localStorageGetItem, RESCHEDULED_ORDER_ID);
        if (latestOrderId) {
            params = { ...params, order_id: latestOrderId };
        }
        yield put(storeQueryParams(params));
        // if token is not expired means it is authenticated
        const idToken = (yield call(getFirebaseIdToken));
        if (idToken && !isTokenExpired(idToken)) {
            // redirect to authenticate
            const parsedToken = (yield call(parseToken, idToken));
            const session = {
                accessToken: idToken,
            };
            yield put(storeStoredSession(session));
            yield put(authenticate({ ...session, parsedToken }));
        }
        else if (params.c) {
            yield call(authenticateWorker, {
                hash_token: params.c,
            });
        }
        yield put(initializeComplete());
    }
    catch (error) {
        yield call(catchError, {
            title: 'Auth initializeWorker',
            error: error,
        });
    }
}
export function* getEmailOtpWorker({ payload }) {
    try {
        yield call(postData, {
            queryString: OTP_REQUEST,
            payload: {
                username: payload?.username,
            },
            spreadPayload: true,
            context: {
                skipAuthorization: true,
                skipHasuraRole: true,
            },
        });
        yield put(getEmailOtpSuccess({ username: payload?.username }));
        if (payload?.callback?.onSuccess) {
            yield call(payload.callback.onSuccess);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'Email Otp Worker',
            error: error,
        });
        if (payload?.callback?.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* getPhoneFireBaseOtpWorker({ payload }) {
    try {
        const result = yield call(signInPhone, payload?.phone);
        if (result) {
            const { verificationId } = result;
            yield put(getPhoneFirebaseOtpSuccess({ verificationId, phone: payload?.phone }));
        }
        else {
            // TODO: handle no result case
            throw Error('Not able to generate the Id token');
        }
        if (payload.callback.onSuccess) {
            yield call(payload.callback.onSuccess);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'Firebase Otp Worker',
            error: error,
        });
        if (payload.callback.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* verifyEmailOtpWorker({ payload }) {
    try {
        const state = (yield select(selectAuth));
        const response = yield call(postData, {
            queryString: VERIFY_OTP,
            payload: {
                username: payload?.username,
                tenant_id: state?.queryParams?.tid && Number(state?.queryParams?.tid),
                code: payload?.code,
            },
            context: {
                skipAuthorization: true,
                skipHasuraRole: true,
            },
        });
        const idToken = get(response, 'data.canx_verify_otp.data.id_token');
        if (idToken) {
            yield put(storeIdToken(idToken));
        }
        else {
            throw Error('Not able to generate the Id token');
        }
        if (payload?.callback?.onSuccess) {
            yield call(payload.callback.onSuccess);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'Verify Otp Worker',
            error: error,
        });
        if (payload?.callback?.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* verifyFirebaseOtpWorker({ payload }) {
    try {
        const state = (yield select(selectAuth));
        const result = yield call(signInCredential, state.verificationId, payload?.code);
        if (result) {
            const { user } = result;
            if (user) {
                // @ts-expect-error // TODO: stsTokenManager is not defined in User type custom type have to define
                const stsTokenManage = user?.stsTokenManager?.toJSON();
                yield put(storeIdToken(stsTokenManage?.accessToken));
            }
            else {
                // TODO: handle no token generation
                throw Error('Failed to generate access token from firebase');
            }
        }
        else {
            // TODO: no result
            throw Error('Not able to sign in with firebase');
        }
        if (payload?.callback?.onSuccess) {
            yield call(payload.callback.onSuccess);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'firebase verify Otp Worker',
            error: error,
        });
        if (payload?.callback?.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* signupWorker() {
    try {
        const idToken = (yield select(selectIdToken));
        const queryParams = (yield select(selectQueryParams));
        const response = (yield call(postData, {
            queryString: SIGNUP,
            payload: {
                id_token: idToken,
                tenant_id: queryParams.tid && Number(queryParams.tid),
            },
            context: {
                skipAuthorization: true,
                skipHasuraRole: true,
            },
        }));
        const result = response?.data?.canx_signup?.[0];
        if (result && result.success) {
            const accessToken = result.data.access_token;
            yield call(authenticateWorker, { id_token: accessToken });
        }
        else {
            const err = Array.isArray(result?.error_message) ? result?.error_message?.[0] : result?.error_message;
            throw Error(`Failed in signupWorker: ${err}`);
        }
    }
    catch (error) {
        yield put(authenticateFailed());
        yield call(catchError, {
            title: 'Authenticate User Worker',
            error: error,
        });
    }
}
export function* authenticateWorker({ hash_token, id_token }) {
    try {
        let response;
        if (id_token) {
            response = (yield call(postData, {
                queryString: GET_CUSTOM_TOKEN_BY_ID_TOKEN,
                payload: {
                    id_token,
                },
                spreadPayload: true,
                context: {
                    skipAuthorization: true,
                    skipHasuraRole: true,
                },
            }));
        }
        else {
            response = (yield call(postData, {
                queryString: GET_CUSTOM_TOKEN,
                payload: {
                    hash_token,
                },
                spreadPayload: true,
                context: {
                    skipAuthorization: true,
                    skipHasuraRole: true,
                },
            }));
        }
        const customToken = response?.data?.auth_get_custom_token?.custom_token;
        if (customToken) {
            const idToken = (yield call(authenticateFirebase, {
                customToken,
            }));
            /*
              The idToken which is getting generate in the initial state from firebase
              doesn't contains the claims. So we have to collect the custom_token from the idToken
              and then authenticate the firebase with the custom_token. In return of that what idToken the
              firebase returns is the accessToken which we have to use to authorization
             */
            if (idToken) {
                const parsedToken = (yield call(parseToken, idToken));
                const session = {
                    accessToken: idToken,
                    customToken,
                    hash_token,
                };
                yield put(authenticate({ ...session, parsedToken }));
            }
            else {
                throw Error('Failed to generate idToken from firebase');
            }
        }
        else {
            throw Error('Not able to generate the custom token.');
        }
    }
    catch (error) {
        yield put(authenticateFailed());
        yield call(catchError, {
            title: 'Authenticate Worker',
            error: error,
        });
    }
}
export function* socialLoginWorker({ payload }) {
    try {
        const response = (yield call(initSocialLogin, payload.socialLoginProviderName));
        if (response && response?.idToken) {
            yield put(storeIdToken(response.idToken));
            if (payload?.callback?.onSuccess) {
                yield call(payload.callback.onSuccess);
            }
        }
        else {
            throw Error('Id token not found');
        }
    }
    catch (error) {
        if (payload?.callback?.onError) {
            yield call(payload?.callback?.onError);
        }
        yield call(catchError, {
            title: 'firebase social login worker',
            error: error,
        });
    }
}
export function* storeQueryParamsWorker() {
    try {
        const params = yield select(selectQueryParams);
        yield call(localStorageSetItem, QUERY_PARAMS_STORAGE_KEY, JSON.stringify(params));
    }
    catch (error) {
        yield call(catchError, {
            title: 'storeQueryParamsWorker',
            error: error,
        });
    }
}
export function* unAuthenticateWorker(action) {
    try {
        yield call(firebaseLogout);
        yield call(client.resetStore);
        if (action.payload?.shouldReload) {
            // yield call([window.location, 'reload']); // It will reload the page immediately
            // adding setTimeOut because want to wait for 1.5sec then reload the page, so user the read the error
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'UnAuthenticate Worker',
            error: error,
            skipToast: false,
        });
    }
}
export function* fetchCurrentCandidateUser({ payload }) {
    try {
        const queryResponse = (yield call(fetchData, {
            queryString: GET_CURRENT_CANDIDATE_USER,
            queryVariables: {},
            forceRefresh: true,
        }));
        const countryCode = (yield call(fetchCountryCodeApi));
        const canCandidate = first(queryResponse?.can_candidate) || {};
        const authUser = queryResponse?.auth_user_me;
        if (canCandidate && authUser) {
            const data = {
                ...canCandidate,
                user: authUser,
                countryCode,
            };
            yield put(storeCandidateUser(data));
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'Fetch Current Candidate User Worker',
            error: error,
            skipToast: false,
        });
        if (payload?.callback?.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* initializeWatcher() {
    yield debounce(1000, initialize.type, initializeWorker);
}
export function* getEmailOtpWatcher() {
    yield takeLatest(getEmailOtp?.type, getEmailOtpWorker);
}
export function* getPhoneFirebaseOtpWatcher() {
    yield takeLatest(getPhoneFirebaseOtp?.type, getPhoneFireBaseOtpWorker);
}
export function* verifyEmailOtpWatcher() {
    yield takeLatest(verifyEmailOtp.type, verifyEmailOtpWorker);
}
export function* verifyFirebaseOtpWatcher() {
    yield takeLatest(verifyFirebaseOtp.type, verifyFirebaseOtpWorker);
}
export function* signupWatcher() {
    yield takeLatest([storeIdToken.type], signupWorker);
}
export function* socialLoginWatcher() {
    yield takeLatest(initializeSocialLogin.type, socialLoginWorker);
}
export function* storeQueryParamsWatcher() {
    yield takeLatest(storeQueryParams.type, storeQueryParamsWorker);
}
export function* fetchCurrentCandidateUserWatcher() {
    yield takeLatest([fetchCandidateUser.type, authenticate.type], fetchCurrentCandidateUser);
}
export function* unAuthenticateWatcher() {
    yield takeLatest(unAuthenticate.type, unAuthenticateWorker);
}
export default function* authRootSaga() {
    yield fork(initializeWatcher);
    yield fork(getEmailOtpWatcher);
    yield fork(getPhoneFirebaseOtpWatcher);
    yield fork(verifyEmailOtpWatcher);
    yield fork(verifyFirebaseOtpWatcher);
    yield fork(signupWatcher);
    yield fork(socialLoginWatcher);
    yield fork(storeQueryParamsWatcher);
    yield fork(fetchCurrentCandidateUserWatcher);
    yield fork(unAuthenticateWatcher);
}
