import { createSelector } from '@reduxjs/toolkit';
import { schedulingSlice } from '@containers/Scheduling/slice';
export const selectScheduling = createSelector((state) => state, (state) => state[schedulingSlice.name]);
export const selectMeeting = createSelector(selectScheduling, (state) => state?.data?.meeting);
export const selectParticipant = createSelector(selectScheduling, (state) => state?.data?.participant);
export const selectMeetingState = createSelector(selectScheduling, (state) => state?.meetingState);
export const selectHasMeetingStarted = createSelector(selectMeetingState, (state) => state?.isMeetingStarted);
export const selectIsMeetingCancelled = createSelector(selectMeetingState, (state) => state?.isMeetingCancelled);
export const selectIsMeetingCompleted = createSelector(selectMeetingState, (state) => state?.isMeetingCompleted);
export const selectIsMeetingDeclined = createSelector(selectMeetingState, (state) => state?.isMeetingDeclined);
export const selectIsCurrentUserParticipant = createSelector(selectMeetingState, (state) => state.isCurrentUserParticipant);
export const selectMeetingConditionType = createSelector(selectMeetingState, (state) => state?.meetingConditionType);
