import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/**
 *
 * Notification
 *
 */
import { memo } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Content from './Content';
export const Notification = (props) => {
    const { children, variant, message, actions, closeToast } = props;
    return (_jsx(_Fragment, { children: children || (_jsx(Content, { title: message.title, summary: message.summary, close: closeToast, actions: actions, variant: variant })) }));
};
export const triggerToast = (arg) => {
    const { setting = {}, ...props } = arg;
    const defaultSettings = {
        theme: 'light',
        icon: false,
        closeButton: false,
        hideProgressBar: true,
        autoClose: 5000,
        ...setting,
        position: 'bottom-right',
    };
    toast(_jsx(Notification, { ...props }), { ...defaultSettings });
};
triggerToast.propTypes = {
    setting: PropTypes.object,
    type: PropTypes.string,
    variant: PropTypes.oneOf(['success', 'info', 'warning', 'danger', 'default', 'processing']),
    message: PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]).isRequired,
        summary: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    }).isRequired,
    actions: PropTypes.any,
    isAnimate: PropTypes.bool,
    showBorder: PropTypes.bool,
    closeToast: PropTypes.func,
};
export default memo(Notification);
