import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { catchError } from '@utils/sentry';
import postData from '@utils/postData';
import { fetchCurrentCandidateUser } from '@containers/Auth/saga';
import { UPDATE_CANDIDATE_DETAILS_MUTATION } from './queries';
import { updateCandidateRequest, updateCandidateSuccess } from './slice';
export function* updateCandidateSagaWorker({ payload }) {
    try {
        const { data } = payload;
        const queryResponse = (yield call(postData, {
            queryString: UPDATE_CANDIDATE_DETAILS_MUTATION,
            payload: data,
        }));
        const candidateData = queryResponse?.data?.can_update_candidate?.[0];
        if (candidateData.success) {
            yield call(fetchCurrentCandidateUser, { payload: { callback: undefined }, type: '' });
            yield put(updateCandidateSuccess({ data: candidateData.data }));
            if (payload?.callback?.onSuccess) {
                yield call(payload?.callback?.onSuccess, candidateData.data);
            }
        }
        else {
            throw new Error(candidateData.error_message || 'An error occurred while updating candidate details.');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Update candidate worker', error: error });
        if (payload?.callback?.onError) {
            yield call(payload?.callback.onError, error);
        }
    }
}
export function* updateCandidateWatcher() {
    yield takeLatest(updateCandidateRequest.type, updateCandidateSagaWorker);
}
export function* updateCandidateRootSaga() {
    yield fork(updateCandidateWatcher);
}
