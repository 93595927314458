import fetchData from '@utils/fetchData';
import { call, cancel, delay, fork, take, takeEvery } from '@redux-saga/core/effects';
import { catchError } from '@utils/sentry';
import { fetchFileUrl, stopHeartBeat, triggerHeardBeat, } from '@containers/App/types';
import { FETCH_FILE_URL, HEART_BEAT } from '@containers/App/queries';
export function* fetchFileUrlSaga({ payload: { data, callback }, }) {
    const queryVariables = {
        id: Number(data.id),
    };
    try {
        const response = (yield call(fetchData, {
            queryString: FETCH_FILE_URL,
            queryVariables,
        }));
        const data = response?.file_download_file;
        if (data && data?.resource_url && callback?.onSuccess) {
            yield call(callback?.onSuccess, data);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'fetchFileUrlSaga',
            extraScope: { key: 'file_id', value: String(data.id) }, // Example usage of extraScope
            error: error,
        });
        if (callback && callback?.onError) {
            yield call(callback?.onError, error);
        }
    }
}
let secondsElapsed = 0;
let timer = null;
const startSecondsElapseTimer = (start = false) => {
    if (start) {
        startSecondsElapseTimer(false);
        timer = setInterval(() => {
            secondsElapsed += 1;
        }, 1000);
    }
    else if (!start) {
        secondsElapsed = 0;
        if (timer) {
            clearInterval(timer);
        }
        timer = null;
    }
};
export const makeHeartBeatApiCall = async (payload) => {
    try {
        await fetchData({
            queryString: HEART_BEAT,
            queryVariables: { ...payload, seconds_elapsed: secondsElapsed },
            forceRefresh: true,
        });
        startSecondsElapseTimer(true);
    }
    catch (error) {
        catchError({
            title: 'makeHeartBeatApiCall',
            error: error,
            skipToast: true,
            extraScope: { key: 'payload', value: JSON.stringify(payload) },
        });
        return Promise.resolve();
    }
};
export function* heartBeatWorker({ payload }) {
    try {
        while (true) {
            yield call(makeHeartBeatApiCall, payload);
            yield delay(10000);
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'heartBeatWorker',
            error: error,
            skipToast: true,
            extraScope: { key: 'payload', value: JSON.stringify(payload) },
        });
        yield delay(2000);
    }
}
export function* heartBeatWatcher() {
    while (true) {
        const { payload } = yield take(triggerHeardBeat);
        const task = (yield fork(heartBeatWorker, { payload }));
        const stopAction = (yield take(stopHeartBeat));
        yield call(startSecondsElapseTimer, false);
        if (stopAction) {
            yield cancel(task);
        }
    }
}
export function* fetchFileUrlWatcher() {
    yield takeEvery(fetchFileUrl, fetchFileUrlSaga);
}
export function* appRootSaga() {
    yield fork(fetchFileUrlWatcher);
    yield fork(heartBeatWatcher);
}
