export function createMockMeetingData() {
    return {
        drive_schedule: {
            slot_capacity: 10,
            slot_duration: '00:30:00',
        },
        candidate_user_id: 123,
        drive_guest_sequence: '1A',
        drive_occurrence_guest_id: 456,
        drive_occurrence_id: 789,
        drive_schedule_id: 101112,
        meeting_id: 'mock-meeting-id-001',
        meeting: {
            duration: '00:30:00',
            from: '2023-12-21T09:00:00Z',
            meeting_status: 'Scheduled',
            meeting_type: 'Interview',
            meeting_participants: [{ online_meeting_link: 'https://mock.meeting.link/join/001' }],
        },
    };
}
