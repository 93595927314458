import { createSlice } from '@reduxjs/toolkit';
export const initialState = {};
export const joinInterviewSlice = createSlice({
    name: 'joinInterview',
    initialState,
    reducers: {
        updateMeetingParticipationStatusRequest(_state, _action) {
            // state.isLoading = true;
        },
    },
});
export const { updateMeetingParticipationStatusRequest } = joinInterviewSlice.actions;
export default joinInterviewSlice.reducer;
