import { all, fork, select } from '@redux-saga/core/effects';
import { initialize } from './slice';
import { call, debounce } from 'redux-saga/effects';
import { selectCandidate } from '@containers/Auth/selectors';
import { catchError } from '@utils/sentry';
import { getConsentStep } from '@containers/Consent/helpers';
export function* initializeWorker({ payload }) {
    try {
        const candidate = (yield select(selectCandidate));
        const state = (yield call(getConsentStep, candidate));
        yield call(payload.callback.onSuccess, state);
    }
    catch (error) {
        yield call(payload.callback.onError);
        yield call(catchError, {
            title: 'Consent initialization failed',
            error: error,
        });
    }
}
export function* initializeWatcher() {
    yield debounce(500, initialize.type, initializeWorker);
}
export function* consentRootSaga() {
    yield all([fork(initializeWatcher)]);
}
