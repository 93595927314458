import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { catchError } from '@utils/sentry';
import { fetchFormDataRequest, fetchFormDataSuccess } from './slice';
import { GET_FORM_INSTANCE_DATA, GET_FORM_VERSION_DATA } from './queries';
import fetchData from '@utils/fetchData';
export function* fetchStandaloneFormDataSaga({ payload }) {
    try {
        const { id, form_id } = payload;
        if (id) {
            const queryResponse = yield call(fetchData, {
                queryString: GET_FORM_INSTANCE_DATA,
                queryVariables: { id: id },
                queryKey: 'frm_form_instance',
                forceRefresh: true,
            });
            const formTestInstanceResponse = queryResponse?.[0];
            yield put(fetchFormDataSuccess(formTestInstanceResponse));
        }
        else if (form_id) {
            const queryResponse = yield call(fetchData, {
                queryString: GET_FORM_VERSION_DATA,
                queryVariables: { form_id: form_id },
                queryKey: 'frm_form_version',
                forceRefresh: true,
            });
            const versionResponse = queryResponse?.[0];
            if (!versionResponse && !versionResponse.id) {
                throw new Error('No form version found');
            }
            const versionResponseData = versionResponse;
            const data = {
                form_version: {
                    form_id: versionResponseData?.form_id,
                    form: {
                        id: versionResponseData?.form_id,
                    },
                    status: versionResponseData?.status,
                    id: versionResponseData?.id,
                },
            };
            yield put(fetchFormDataSuccess(data));
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Standalone forms error', error: error });
    }
}
export function* fetchStandaloneFormWatcherSaga() {
    yield takeLatest(fetchFormDataRequest.type, fetchStandaloneFormDataSaga);
}
export function* standaloneFormRootSaga() {
    yield fork(fetchStandaloneFormWatcherSaga);
}
