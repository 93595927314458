import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    request: {
        tcfLoading: false,
        isLoading: true,
    },
    data: {
        assessment: null,
        assessmentSection: null,
        currentQuestion: null,
        questions: null,
        answer: null,
        appState: null,
        testInstance: null,
        assessmentSections: [],
        startAssessmentResponse: null,
        startSectionResponse: null,
        next_question_id: null,
        questionsIds: [],
        answers: [],
        currentAssessmentSectionId: undefined,
        parsedMaxSectionTime: null,
        assessmentError: null,
        tcfMeasurement: null,
        candidate_id: undefined,
        step: null,
    },
    preInstructionState: {
        hasPreform: false,
        preformSubmitted: false,
        formId: null,
    },
};
export const assessmentSlice = createSlice({
    name: 'assessment',
    initialState,
    reducers: {
        initialize: (_state, _action) => ({
            ...initialState,
            request: {
                ...initialState.request,
                isLoading: true,
            },
        }),
        startAssessment: (_state, _action) => {
            // state.request.isLoading = true;
        },
        startAssessmentError: (state, action) => {
            state.data.assessmentError = action.payload.error;
            state.request.isLoading = false;
        },
        storeAssessment: (state, action) => {
            state.data.assessment = action.payload?.assessment;
            state.data.currentAssessmentSectionId = action.payload?.assessmentSection.id;
            state.data.assessmentSections = action.payload?.assessmentSections;
            state.data.startAssessmentResponse = action.payload?.startAssessmentResponse;
            state.request.isLoading = false;
        },
        resetSectionState: (state) => {
            state.request.tcfLoading = false;
            state.data.assessmentSection = null;
            state.data.currentQuestion = null;
            state.data.questions = null;
            state.data.answer = null;
            state.data.appState = null;
            state.data.startSectionResponse = null;
            state.data.next_question_id = null;
            state.data.questionsIds = [];
            state.data.answers = [];
            state.data.currentAssessmentSectionId = undefined;
            state.data.parsedMaxSectionTime = null;
            state.data.tcfMeasurement = null;
        },
        startSection: (_state, _action) => {
            // state.request.isLoading = true
        },
        storeSection: (state, action) => {
            state.data.assessmentSection = action.payload.assessmentSection;
            state.data.startSectionResponse = action.payload.startSectionResponse;
            state.data.next_question_id = action.payload.next_question_id;
            state.data.questionsIds = action.payload.questionsIds;
            state.data.parsedMaxSectionTime = action.payload.parsedMaxSectionTime;
            state.data.answers = action.payload.answers;
        },
        submitSection: (_state, _action) => {
            // state.request.isLoading = true
        },
        storeStopSection: (state, action) => {
            state.data.startSectionResponse = action.payload.startSectionResponse;
        },
        fetchNextQuestionById: (state, _action) => {
            state.data.answer = null;
        },
        fetchNextQuestionFromCollection: (state, _action) => {
            state.data.answer = null;
        },
        storeNextQuestion: (state, action) => {
            state.data.currentQuestion = action.payload.data;
            state.data.next_question_id = action.payload.next_question_id;
        },
        fetchQuestionsList: () => {
            // state.request.isLoading = true
        },
        storeQuestionList: (state, action) => {
            state.data.questions = action.payload.data;
        },
        saveAnswer: (_state, _action) => {
            // state.request.isLoading = true
        },
        storeAnswer: (state, action) => {
            state.data.answer = action.payload;
            state.data.answers.push(action.payload);
        },
        storeBulkAnswer: (state, action) => {
            state.data.answers = action.payload;
        },
        stopSection: (_state, _action) => {
            // state.request.isLoading = true;
        },
        fetchTcfMeasure: (state, _action) => {
            state.request.tcfLoading = true;
        },
        saveAppState: (_state, _action) => {
            // state.request.isLoading = true
        },
        storeAppState: (state, action) => {
            state.data.appState = action.payload;
        },
        getAppState: (_state, _action) => {
            // state.request.isLoading = true;
        },
        storeTcfMeasurement: (state, action) => {
            state.request.tcfLoading = false;
            state.data.tcfMeasurement = action.payload;
        },
        fetchTestInstance: (_state, _action) => {
            // state.request.isLoading = true;
        },
        saveBulkAnswer: (_state, _action) => {
            // state.request.isLoading = true;
        },
        storeStepDetails: (state, action) => {
            state.data.step = action.payload;
        },
        updatePreInstructionState: (state, action) => {
            state.preInstructionState.hasPreform = action?.payload?.hasPreform || state.preInstructionState.hasPreform;
            state.preInstructionState.preformSubmitted =
                action?.payload?.preformSubmitted || state.preInstructionState.preformSubmitted;
            state.preInstructionState.formId = action?.payload?.formId || state.preInstructionState.formId;
        },
    },
});
export const { initialize, startAssessment, startSection, resetSectionState, submitSection, fetchNextQuestionById, fetchQuestionsList, saveAnswer, stopSection, fetchTcfMeasure, saveAppState, storeAssessment, storeSection, storeNextQuestion, storeQuestionList, storeAnswer, storeAppState, getAppState, storeStopSection, fetchTestInstance, startAssessmentError, storeTcfMeasurement, fetchNextQuestionFromCollection, saveBulkAnswer, storeBulkAnswer, storeStepDetails, updatePreInstructionState, } = assessmentSlice.actions;
export default assessmentSlice.reducer;
