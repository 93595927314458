import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    initializationCompleted: false,
};
export const consentSlice = createSlice({
    name: 'consent',
    initialState,
    reducers: {
        initialize: (state, _action) => {
            state.initializationCompleted = false;
        },
        initializationCompleted: (state, _action) => {
            state.initializationCompleted = true;
        },
    },
});
export const { initialize, initializationCompleted } = consentSlice.actions;
export default consentSlice.reducer;
