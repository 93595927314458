import { call, fork, put, select, takeLatest } from '@redux-saga/core/effects';
import postData from '@utils/postData';
import { catchError } from '@utils/sentry';
import { selectTestInstanceId } from '@containers/Assessment/selectors';
import { createFeedback, storeFeedbackResponse } from './slice';
import { CREATE_FEEDBACK } from './queries';
export function* createFeedbackWorker({ payload }) {
    try {
        const testInstanceId = (yield select(selectTestInstanceId));
        const response = (yield call(postData, {
            queryString: CREATE_FEEDBACK,
            payload: [
                {
                    rating: payload?.data?.rating,
                    comment: payload?.data?.comment,
                    user_agent: payload?.data?.user_agent,
                    instance_id: testInstanceId,
                    region: payload?.data?.region,
                },
            ],
        }));
        const feedbackResponse = response?.data?.canx_create_feedback[0];
        if (feedbackResponse) {
            yield put(storeFeedbackResponse({ data: { canx_create_feedback: [feedbackResponse] } }));
            if (payload?.callback?.onSuccess) {
                yield call(payload.callback.onSuccess);
            }
        }
        else {
            throw new Error('Feedback creation failed');
        }
    }
    catch (error) {
        yield call(catchError, {
            title: 'Create Feedback Worker',
            error: error,
        });
        if (payload?.callback?.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* getCreateFeedbackWatcher() {
    yield takeLatest(createFeedback.type, createFeedbackWorker);
}
export function* feedbackRootSaga() {
    yield fork(getCreateFeedbackWatcher);
}
