import { call, fork, takeLatest } from 'redux-saga/effects';
import postData from '@utils/postData';
import { handleSagaError, handleSagaSuccess } from '@utils/sagaHelper';
import { catchException, processSagaError } from '@utils/catchException';
import { updateMeetingParticipationStatusRequest } from './slice';
import { UPDATE_MEETING_PARTICIPATION_STATUS } from './queries';
export function* updateMeetingParticipationStatusSagaWorker({ payload, }) {
    try {
        const { status, meeting_participant_id } = payload.data;
        const queryResponse = (yield call(postData, {
            queryString: UPDATE_MEETING_PARTICIPATION_STATUS,
            payload: { status, meeting_participant_id },
        }));
        const participationStatusResponse = queryResponse?.data?.sch_update_meeting_participant_status[0];
        if (participationStatusResponse?.success) {
            yield call(handleSagaSuccess, {
                callback: payload.callback?.onSuccess,
                response: participationStatusResponse,
            });
        }
        else {
            throw catchException.validationError(`An error occurred while updating the meetingParticipation Status ${participationStatusResponse?.error_message}`, { meeting_participant_id, status, participationStatusResponse });
        }
    }
    catch (error) {
        yield call(handleSagaError, {
            callback: payload.callback?.onError,
            error: processSagaError(error, 'An error occurred in updateMeetingParticipationStatusSagaWorker'),
            title: 'Meeting Participant Update',
            skipToast: true,
        });
    }
}
export function* updateMeetingParticipationStatusSagaWatcher() {
    yield takeLatest(updateMeetingParticipationStatusRequest.type, updateMeetingParticipationStatusSagaWorker);
}
export function* joinInterviewRootSaga() {
    yield fork(updateMeetingParticipationStatusSagaWatcher);
}
