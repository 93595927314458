import { fork } from '@redux-saga/core/effects';
// Auth Saga
import authRootSaga from '@containers/Auth/saga';
// Assessment saga
import { assessmentRootSaga } from '@containers/Assessment/saga';
import { asyncAnswerRootSaga } from '@containers/Assessment/AsyncPlayGround/saga';
// App Saga
import { appRootSaga } from '@containers/App/saga';
// Feedback Saga
import { feedbackRootSaga } from '@containers/Feedback/saga';
// Drive Saga
import { driveRootSaga } from '@containers/Drive/saga';
import { rootDriveRegistrationSaga } from '@containers/Drive/Register/saga';
// CandidateRegistration Saga
import { updateCandidateRootSaga } from '@containers/CandidateRegistration/saga';
// Payment Saga
import { fetchOrderDetailsRootSaga } from '@containers/Payment/saga';
// Scheduling Saga
import { schedulingRootSaga } from '@containers/Scheduling/saga';
import { meetingSlotsRootSaga } from '@containers/Scheduling/SlotBooking/saga';
import { acceptProposedTimeSaga } from '@containers/Scheduling/AcceptProposedTime/saga';
import { proposedTimeSaga } from '@containers/Scheduling/ProposeNewTime/saga';
import { interviewDeclineSaga } from '@containers/Scheduling/InterviewDecline/saga';
import { declineProposedTimeSaga } from '@containers/Scheduling/DeclineProposedTime/saga';
import { joinInterviewRootSaga } from '@containers/Scheduling/JoinInterview/saga';
import { interviewAcceptSaga } from '@containers/Scheduling/ConfirmInterview/saga';
// StandaloneForms Saga
import { standaloneFormRootSaga } from '@containers/Standalone/saga';
// FaceAuth profile creating
import { biometricRootSaga } from '@containers/BiometricAuthentication/FaceAuth/saga';
import { consentRootSaga } from '@containers/Consent/saga';
// LongFormPlayGround Saga
import { longFormAnswerRootSaga } from '@containers/Assessment/LongFormPlayGround/saga';
export default function* rootSaga() {
    yield fork(authRootSaga);
    yield fork(assessmentRootSaga);
    yield fork(appRootSaga);
    yield fork(feedbackRootSaga);
    yield fork(meetingSlotsRootSaga);
    yield fork(updateCandidateRootSaga);
    yield fork(asyncAnswerRootSaga);
    yield fork(fetchOrderDetailsRootSaga);
    yield fork(schedulingRootSaga);
    yield fork(driveRootSaga);
    yield fork(acceptProposedTimeSaga);
    yield fork(proposedTimeSaga);
    yield fork(interviewDeclineSaga);
    yield fork(interviewAcceptSaga);
    yield fork(rootDriveRegistrationSaga);
    yield fork(declineProposedTimeSaga);
    yield fork(joinInterviewRootSaga);
    yield fork(standaloneFormRootSaga);
    yield fork(biometricRootSaga);
    yield fork(consentRootSaga);
    yield fork(longFormAnswerRootSaga);
}
