import { defineMessages } from 'react-intl';
export default defineMessages({
    loadingError: {
        id: 'app.components.Pages.GrowthBookError.loadingError',
        defaultMessage: 'Loading Error',
    },
    pageBodyMessage: {
        id: 'app.components.Pages.GrowthBookError.pageBodyMessage',
        defaultMessage: 'We encountered an issue while loading the application. Please click the button below to retry.',
    },
    refresh: {
        id: 'app.components.Pages.GrowthBookError.refresh',
        defaultMessage: 'Refresh',
    },
});
