import { createSlice } from '@reduxjs/toolkit';
import { getAllParams } from '@containers/Auth/helpers';
export const initialState = {
    initializing: false,
    initialized: false,
    authenticated: false,
    authenticating: false,
    idToken: null,
    accessToken: null,
    expiryTime: null,
    verificationCodeRequested: false,
    user: {},
    queryParams: {},
    verificationId: '',
    candidate: null,
    candidateLoading: true,
};
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        initialize: () => ({
            ...initialState,
            initializing: true,
            queryParams: getAllParams(),
        }),
        initializeComplete: (state) => {
            state.initializing = false;
            state.initialized = true;
        },
        authenticate: (state, action) => {
            state.initializing = false;
            state.initialized = true;
            state.authenticated = !!(action.payload?.accessToken || '').trim();
            state.authenticating = false;
            state.accessToken = action.payload?.accessToken || null;
            state.customToken = action.payload?.customToken || null;
            state.hash_token = action.payload?.hash_token || null;
            state.expiryTime = action.payload?.parsedToken?.expiryTime || null;
            state.user = {
                user_id: action.payload?.parsedToken?.user_id || null,
                tenant_id: action.payload?.parsedToken?.tenant_id || null,
                roles: action.payload?.parsedToken?.roles || null,
            };
        },
        authenticateFailed: (state) => {
            state.initializing = false;
            state.initialized = true;
            state.authenticated = false;
            state.authenticating = false;
        },
        unAuthenticate: (state, _action) => {
            state.initializing = false;
            state.initialized = true;
            state.authenticated = false;
            state.authenticating = false;
            state.candidateLoading = true;
            state.candidate = null;
        },
        getEmailOtp: (_state, _action) => {
            // state.user.username = action.payload.username;
        },
        getPhoneFirebaseOtp: (_state, _action) => {
            // state.user.phone = action.payload.phone;
        },
        getEmailOtpSuccess: (state, action) => {
            state.user.username = action.payload.username;
            state.verificationCodeRequested = true;
        },
        getPhoneFirebaseOtpSuccess: (state, action) => {
            state.verificationId = action.payload?.verificationId;
            state.user.phone = action.payload.phone;
            state.verificationCodeRequested = true;
        },
        verifyEmailOtp: (_state, _action) => {
            // state.user.username = action.payload.username;
        },
        verifyFirebaseOtp: (_state, _action) => {
            // state.user.phone = action.payload.phone;
        },
        storeIdToken: (state, action) => {
            state.idToken = action.payload;
            state.authenticating = true;
        },
        storeQueryParams: (state, action) => {
            state.queryParams = action.payload;
        },
        initializeSocialLogin: (_state, _action) => {
            // state.user.username = action.payload.username;
        },
        storeStoredSession: (state, action) => {
            state.accessToken = action.payload.accessToken ?? null;
            state.idToken = action.payload.idToken ?? null;
            state.customToken = action.payload.customToken;
            state.hash_token = action.payload.hash_token;
            state.tenant_id = action.payload.tenant_id ?? null;
        },
        storeUser: (state, action) => {
            state.user = {
                ...state.user,
                phone_number: action.payload?.phone_number || state.user.phone,
                email: action.payload?.email || state.user.username,
                username: action.payload?.email || state.user.username,
            };
        },
        fetchCandidateUser: (_state, _action) => {
            // state.candidateLoading = true;
        },
        storeCandidateUser: (state, action) => {
            state.candidate = action.payload;
            state.candidateLoading = false;
        },
    },
});
export const { initialize, initializeComplete, authenticate, unAuthenticate, getEmailOtp, getPhoneFirebaseOtp, getEmailOtpSuccess, verifyEmailOtp, verifyFirebaseOtp, getPhoneFirebaseOtpSuccess, authenticateFailed, storeIdToken, storeQueryParams, initializeSocialLogin, storeStoredSession, storeUser, storeCandidateUser, fetchCandidateUser, } = authSlice.actions;
export default authSlice.reducer;
