import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    data: null,
    loading: false,
    validateCoupon: false,
    couponDetails: {
        couponCode: null,
        discountAmount: 0,
        isValid: false,
        quantity: 0,
        unit_price: 0,
        total_price: 0,
        isError: false,
    },
    rescheduledSlotData: null,
    invoiceResponse: null,
};
export const paymentSlice = createSlice({
    name: 'paymentDetails',
    initialState,
    reducers: {
        fetchOrderDetailsRequest: (state, _action) => {
            state.loading = true;
        },
        fetchOrderDetailsSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        createTransactionRequest: (state, _action) => {
            state.loading = true;
        },
        createTransactionSuccess: (state) => {
            state.loading = false;
        },
        validateCouponRequest: (state, action) => {
            state.validateCoupon = true;
            state.couponDetails.couponCode = action.payload.coupon_code;
            state.couponDetails.discountAmount = initialState.couponDetails.discountAmount;
            state.couponDetails.isValid = initialState.couponDetails.isValid;
            state.couponDetails.quantity = initialState.couponDetails.quantity;
            state.couponDetails.unit_price = initialState.couponDetails.unit_price;
            state.couponDetails.total_price = initialState.couponDetails.total_price;
            state.couponDetails.isError = false;
        },
        validateCouponSuccess: (state, action) => {
            state.validateCoupon = false;
            if (action?.payload?.isValid) {
                const item_quantity = action?.payload?.item_quantity || 0;
                const item_unitPrice = action?.payload?.item_unitPrice || 0;
                const item_totalPrice = action?.payload?.item_totalPrice || 0;
                state.couponDetails.discountAmount = item_quantity * item_unitPrice - item_totalPrice;
                state.couponDetails.isValid = action?.payload?.isValid; // Have to pass from the saga
                state.couponDetails.quantity = item_quantity;
                state.couponDetails.unit_price = item_unitPrice; // Total price of the item multiplied by the quantity => Sub total
                state.couponDetails.total_price = item_totalPrice; // Nothing but the price of the one single item
                state.couponDetails.isError = false;
            }
        },
        removeAppliedCoupon: (state) => {
            state.couponDetails = initialState.couponDetails;
        },
        validateCouponFailed: (state) => {
            state.couponDetails.isValid = false;
            state.couponDetails.isError = true;
            // state.couponDetails = initialState.couponDetails;
            // Todo: Have to is order properties need to restore or not.
        },
        paymentRescheduledSlotRequest: (state, _action) => {
            state.loading = true;
        },
        paymentRescheduledSlotSuccess: (state, action) => {
            state.loading = false;
            state.rescheduledSlotData = action.payload;
        },
        fetchPaymentInvoiceRequest: (state, _action) => {
            state.loading = true;
        },
        fetchPaymentInvoiceSuccess: (state, action) => {
            state.loading = false;
            state.invoiceResponse = action.payload;
        },
    },
});
export const { fetchOrderDetailsRequest, fetchOrderDetailsSuccess, createTransactionRequest, createTransactionSuccess, validateCouponRequest, validateCouponSuccess, removeAppliedCoupon, validateCouponFailed, paymentRescheduledSlotRequest, paymentRescheduledSlotSuccess, fetchPaymentInvoiceRequest, fetchPaymentInvoiceSuccess, } = paymentSlice.actions;
export default paymentSlice.reducer;
