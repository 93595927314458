import { createIntl, createIntlCache } from 'react-intl';
import { get } from 'lodash';
import { LANG_STORAGE_KEY, localStorageGetItem, localStorageSetItem } from '@utils/localStorageHelpers';
import { catchError } from '@utils/sentry';
import esTranslationMessages from '../locale/es.json';
import enTranslationMessages from '../locale/en.json';
export const translations = {
    en: enTranslationMessages,
    es: esTranslationMessages,
};
export const DEFAULT_LOCALE_CONSTANT = 'en';
export const defaultLocale = () => {
    try {
        const val = localStorageGetItem(LANG_STORAGE_KEY);
        if (val) {
            return val;
        }
        return DEFAULT_LOCALE_CONSTANT;
    }
    catch (error) {
        catchError({
            title: 'An error occurred in defaultLocale function',
            error: error,
        });
        return DEFAULT_LOCALE_CONSTANT;
    }
};
export const DEFAULT_LOCALE = defaultLocale();
export const cache = createIntlCache();
const translationContent = translations[DEFAULT_LOCALE];
export let intl = createIntl({ locale: DEFAULT_LOCALE, messages: translationContent }, cache);
export let fmt = intl.formatMessage;
export const changeLanguage = (newLocale) => {
    try {
        const parsedLocale = (newLocale || '').trim().length && get(translations, newLocale) ? newLocale : DEFAULT_LOCALE;
        const translationContent = get(translations, parsedLocale);
        intl = createIntl({ locale: newLocale, messages: translationContent }, cache);
        fmt = intl.formatMessage;
        if (typeof document !== 'undefined') {
            document.documentElement.lang = parsedLocale;
        }
        localStorageSetItem(LANG_STORAGE_KEY, parsedLocale);
        return intl;
    }
    catch (error) {
        catchError({
            title: 'An error occurred in changeLanguage function',
            error: error,
        });
        return intl;
    }
};
export default intl;
