import { catchError } from '@utils/sentry';
import { axiosGetData } from './axiosApi';
export const fetchCountryCodeApi = async () => {
    try {
        const data = await axiosGetData('https://ipapi.co/json');
        return data?.country.toUpperCase();
    }
    catch (error) {
        catchError({
            title: 'ipapi failed to load',
            error: error,
        });
        return '';
    }
};
