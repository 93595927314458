import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { catchError } from '@utils/sentry';
import fetchData from '@utils/fetchData';
import { selectUser } from '@containers/Auth/selectors';
import { fetchMeeting, storeMeeting } from './slice';
import { GET_MEETING } from './queries';
import { selectMeeting } from './selectors';
import { findParticipant, getMeetingStates, meetingType } from './helpers';
export function* fetchMeetingWorker({ payload }) {
    try {
        const user = (yield select(selectUser));
        const cachedMeeting = (yield select(selectMeeting));
        const { meetingId, participantId } = payload;
        const id = meetingId || cachedMeeting?.id;
        const queryVariables = participantId
            ? { meeting_participants: { id: { _eq: participantId } } }
            : { id: { _eq: id } };
        const queryResponse = (yield call(fetchData, {
            queryString: GET_MEETING,
            queryVariables: { filter: queryVariables },
            queryKey: 'sch_meeting',
            forceRefresh: true,
            context: {},
        }));
        const meeting = queryResponse?.[0];
        const userId = Number(user?.user_id);
        const participant = (yield call(findParticipant, meeting, userId, participantId));
        if (meeting && participant) {
            const meetingStates = (yield call(getMeetingStates, meeting, participant));
            const meetingConditionType = (yield call(meetingType, meeting, userId, participantId, participant));
            yield put(storeMeeting({
                meeting,
                participant,
                meetingConditionType,
                ...meetingStates,
            }));
            if (payload.callback?.onSuccess) {
                yield call(payload.callback.onSuccess, meeting);
            }
        }
        else {
            throw new Error('An error occurred while fetching the meetings data');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Meeting Worker', error: error });
        if (payload?.callback?.onError) {
            yield call(payload.callback.onError, error);
        }
    }
}
export function* fetchMeetingWatcher() {
    yield takeLatest(fetchMeeting.type, fetchMeetingWorker);
}
export function* schedulingRootSaga() {
    yield fork(fetchMeetingWatcher);
}
