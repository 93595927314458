import { gql } from '@apollo/client';
export const GET_MEETING = gql `
  query getMeeting($filter: [sch_meeting_bool_exp!]) {
    sch_meeting(where: { _or: $filter }) {
      id
      from
      meeting_status
      to
      duration
      location
      meeting_participants {
        id
        participant_role
        participation_status
        rsvp_status
        user_id
        meta
        meeting_id
        online_meeting_link
        meeting {
          tenant_meeting_service_provider {
            meeting_service_provider_name
            meeting_service_provider {
              has_iframe
            }
          }
        }
        guest {
          email
          name
          external_id
          id
          user_id
        }
      }
      tenant_meeting_service_provider {
        meeting_service_provider_name
        meeting_service_provider {
          has_iframe
        }
      }
    }
  }
`;
