import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AuthProvider } from '@contexts/AuthContext';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { growthBook, initializeGrowthBook } from '@utils/growthBook';
import { useNavigate } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GrowthBookError from '@components/Pages/GrowthBookError';
const RootLayout = () => {
    const [gbInitializationState, setGbInitializationState] = useState('initializing');
    const [gbError, setGbError] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.pathname === '/') {
            navigate(`/login${location.search}`);
        }
    }, [location.pathname, navigate]);
    React.useEffect(() => {
        setGbInitializationState(() => 'initializing');
        initializeGrowthBook()
            .then(() => {
            setGbInitializationState(() => 'initialized');
        })
            .catch((e) => {
            setGbError(e?.message);
            setGbInitializationState(() => 'failed');
        });
        return () => setGbInitializationState(() => 'initializing');
    }, []);
    if (gbInitializationState === 'failed') {
        return _jsx(GrowthBookError, { errorMessage: gbError });
    }
    return (_jsx(GrowthBookProvider, { growthbook: growthBook, children: _jsxs(AuthProvider, { children: [_jsx("div", { className: "h-screen w-screen", children: _jsx(Outlet, {}) }), _jsx(ToastContainer, { style: { width: 'auto' } })] }) }));
};
export default RootLayout;
