import { catchError } from '@utils/sentry';
import { forEach } from 'lodash';
export const appName = `cx-3.x-${process.env.BUILD_ENV}`;
export const AUTH_STORAGE_KEY = 'authentication';
export const DRIVE_OCCURRENCE_GUEST_ID = 'driveOccurrenceGuestId';
export const DRIVE_OCCURRENCE_ID = 'driveOccurrenceId';
export const LANG_STORAGE_KEY = 'lang';
export const LOBBY_WAITING_TIME_REMAINING = 'lobbyWaitingTimeRemaining';
export const QUERY_PARAMS_STORAGE_KEY = 'queryParams';
export const BOOK_PAY_CONFIRM_PAYLOAD = 'bookPayConfirmPayload';
export const RESCHEDULED_ORDER_ID = 'rescheduledOrderId';
export const FACE_AUTH_COMPLETED = 'face_auth_completed';
export const ASSESSMENT_PRE_REGISTRATION_FORM = 'assessment_pre_registration_form';
export const RBS_IN_QUERY = 'rbsInQuery';
export const PREV_ORDER_ID = 'prevOrderId';
const allStorageKeys = [
    AUTH_STORAGE_KEY,
    DRIVE_OCCURRENCE_GUEST_ID,
    DRIVE_OCCURRENCE_ID,
    LANG_STORAGE_KEY,
    LOBBY_WAITING_TIME_REMAINING,
    QUERY_PARAMS_STORAGE_KEY,
    BOOK_PAY_CONFIRM_PAYLOAD,
    RESCHEDULED_ORDER_ID,
    FACE_AUTH_COMPLETED,
    ASSESSMENT_PRE_REGISTRATION_FORM,
    RBS_IN_QUERY,
    PREV_ORDER_ID,
];
export const localStorageSetItem = (key, value) => {
    try {
        if (localStorage && typeof localStorage.setItem === 'function') {
            return localStorage.setItem(`${appName}-${key}`, value);
        }
        throw Error('Either localStorage is undefined or localStorage.setItem is not a function');
    }
    catch (error) {
        catchError({
            title: 'localStorage.setItem not found',
            error: error,
        });
    }
};
export const localStorageGetItem = (key) => {
    try {
        if (localStorage && typeof localStorage.getItem === 'function') {
            return localStorage.getItem(`${appName}-${key}`);
        }
        throw Error('Either localStorage is undefined or localStorage.getItem is not a function');
    }
    catch (error) {
        catchError({
            title: 'localStorage.getItem not found',
            error: error,
        });
        return undefined;
    }
};
export const removeLocalStorageItem = (key) => {
    try {
        if (localStorage && typeof localStorage.removeItem === 'function') {
            return localStorage.removeItem(`${appName}-${key}`);
        }
        throw Error('Either localStorage is undefined or localStorage.removeItem is not a function');
    }
    catch (error) {
        catchError({
            title: 'localStorage.removeItem not found',
            error: error,
        });
    }
};
export const clearLocalStorage = () => {
    try {
        forEach(allStorageKeys, (key) => {
            removeLocalStorageItem(key);
        });
    }
    catch (error) {
        catchError({
            title: 'localStorage.clear not found',
            error: error,
        });
    }
};
