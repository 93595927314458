import { growthBookFeatureFlags } from '@utils/featureFlags';
import { growthBook } from '@utils/growthBook';
import { getConsentCompletion } from '@containers/Consent/helpers';
export const validateShouldSkipFaceAuth = (candidate) => {
    const consentCountryList = growthBook.getFeatureValue(growthBookFeatureFlags.CANDIDATE_CONSENT_LIST, {});
    const countryCode = candidate?.country?.slice(0, 2) || '';
    const isRecordingConsentAccepted = candidate?.is_recording_consent_accepted;
    // Case 1: No recording consent countries - always skip face auth
    const noRecordingConsent = consentCountryList?.noRecording?.includes(countryCode);
    if (noRecordingConsent) {
        return true;
    }
    // Case 2: Always recording consent countries - never skip face auth
    const alwaysRecordingConsent = consentCountryList?.alwaysRecording?.includes(countryCode);
    if (alwaysRecordingConsent) {
        return false;
    }
    // Case 3: Optional recording consent countries
    const isOptionalRecordingCountry = countryCode && !noRecordingConsent && !alwaysRecordingConsent;
    if (isOptionalRecordingCountry) {
        // If they explicitly gave consent (true), do not skip face auth
        // If they didn't give consent (false or undefined), skip face auth
        return isRecordingConsentAccepted !== true;
    }
    // Default case when country and consent is not selected
    return false;
};
export const getFaceAuthStep = (candidate) => {
    const isConsentCompleted = getConsentCompletion(candidate);
    const shouldSkipFaceAuth = validateShouldSkipFaceAuth(candidate);
    if (!isConsentCompleted) {
        return {
            redirectToConsent: true,
            redirectForward: false,
        };
    }
    else if (shouldSkipFaceAuth) {
        return {
            redirectToConsent: false,
            redirectForward: true,
        };
    }
    else {
        return {
            redirectToConsent: false,
            redirectForward: false,
        };
    }
};
