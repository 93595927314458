import { firebaseInstance } from '@utils/firebase';
import { PhoneAuthProvider, RecaptchaVerifier, browserLocalPersistence, setPersistence, signInWithCredential, signInWithCustomToken, signInWithPhoneNumber, signInWithPopup, } from 'firebase/auth';
import { replace, trim } from 'lodash';
import { QUERY_PARAMS_STORAGE_KEY, localStorageGetItem } from '@utils/localStorageHelpers';
import { socialLoginProviders } from '@contexts/AuthContext/socialAuthProvider';
export const signInPhone = (phoneNumber) => {
    const auth = firebaseInstance?.fireBaseAuth;
    const appVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
        size: 'invisible',
        callback: () => {
            console.log('recaptcha resolved..');
        },
    });
    return signInWithPhoneNumber(auth, phoneNumber, appVerifier);
};
export const signInCredential = (verificationId, code) => {
    const auth = firebaseInstance?.fireBaseAuth;
    const credential = PhoneAuthProvider.credential(verificationId, code);
    return signInWithCredential(auth, credential);
};
export const getAllParams = () => {
    const s = window.location.search;
    const search = new URLSearchParams(s);
    const params = {};
    for (const [key, value] of search.entries()) {
        if (key === 'username') {
            params[key] = replace(trim(value), ' ', '+');
        }
        else {
            params[key] = value;
        }
    }
    return params;
};
export async function authenticateFirebase({ customToken }) {
    if (!trim(customToken))
        throw new Error('Invalid Access Token');
    await setPersistence(firebaseInstance.fireBaseAuth, browserLocalPersistence);
    const UC = await signInWithCustomToken(firebaseInstance.fireBaseAuth, customToken);
    if (UC.user) {
        return await UC.user.getIdToken();
    }
    throw new Error('Invalid User');
}
export const getStoredQueryParams = () => {
    const params = localStorageGetItem(QUERY_PARAMS_STORAGE_KEY);
    return params ? JSON.parse(params) : {};
};
export const initSocialLogin = async (socialLoginProviderName) => {
    const auth = firebaseInstance?.fireBaseAuth;
    const provider = socialLoginProviders[socialLoginProviderName];
    if (provider) {
        const result = await signInWithPopup(auth, provider);
        if (result?.user?.getIdToken) {
            const token = await result?.user?.getIdToken();
            const user = {
                username: result?.user?.displayName,
                phone: result?.user?.phoneNumber,
                email: result?.user?.email,
                tenantId: result?.user?.tenantId,
                uid: result?.user?.uid,
                idToken: token,
            };
            return user;
        }
        throw Error('getIdToken not found');
    }
    else {
        throw Error('No provider found on social login, initSocialLogin');
    }
};
export const parseJwt = (token) => {
    if (!token) {
        throw Error('No token provided for parse');
    }
    const base64Url = token.split('.')[1];
    if (base64Url) {
        try {
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64)
                .split('')
                .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
                .join(''));
            return JSON.parse(jsonPayload);
        }
        catch (error) {
            throw Error(`Error parsing JWT: ${error?.message}`);
        }
    }
};
export const parseToken = (accessToken) => {
    const user = parseJwt(accessToken);
    if (user && Object.keys(user).length) {
        const user_id = user?.['https://hasura.io/jwt/claims']?.['X-Hasura-User-Id'] || null;
        const tenant_id = user?.['https://hasura.io/jwt/claims']?.['X-Hasura-Tenant-Id'] || null;
        const roles = user?.['https://hasura.io/jwt/claims']?.['X-Hasura-Allowed-Roles'] || [];
        const expiryTime = user?.exp || null;
        return { user_id, tenant_id, roles, expiryTime };
    }
    throw Error('Parsed token no data');
};
export function isTokenExpired(token) {
    if (token) {
        const user = parseJwt(token); // using atob -> atob(token.split('.')[1])
        const expiryTime = user?.exp || null;
        return Math.floor(new Date().getTime() / 1000) >= expiryTime;
    }
    return true;
}
